import React, { useState } from "react";
import { hot } from "react-hot-loader";
import { Button, Card, CardActions, CardContent, CardHeader, Container, Snackbar, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import AdvanceScheduleUserField from "./AdvanceScheduleUserField";
import AdvanceScheduleDateField from "./AdvanceScheduleDateField";
import AdvanceScheduleReasonField from "./AdvanceScheduleReasonField";
import AdvanceScheduleCostField from "./AdvanceScheduleCostField";
import { parseAmount } from "../../shared/balance_service";
import { AdvanceScheduleDto, createAdvanceSchedule, creationDateBy, updateAdvanceSchedule, validateExpirationBy } from "./advance_schedules_service";
import AdvanceScheduleClassifications from "./AdvanceScheduleClassifications";
import dayjs from "dayjs";
import EspressoTemplate from "../EspressoTemplate";
import { PageNavbar } from "../components/PageNavbar";
import { admin_advance_schedules_path } from "../../../routes";
import { parserCurrencyBRLValue } from "../../shared/helpers";

const AdvanceScheduleConfig = (props) => {
  return <EspressoTemplate {...props}>
    <Stack>
      <PageNavbar
        showBackButton={true}
        title={props.advanceSchedule ? "Editar fundo fixo" : "Novo fundo fixo"}
        backTo={admin_advance_schedules_path()}
      />
      <Container maxWidth={"lg"} sx={{ mt: 5 }}>
        <AdvanceScheduleConfigContent {...props}/>
      </Container>
    </Stack>
  </EspressoTemplate>
}

const AdvanceScheduleConfigContent = (props) => {
  const editing = props.advanceSchedule;
  const initialCost = editing?.cost && parserCurrencyBRLValue(editing.cost, false);
  const initialCurrency = props.currencies.find(currency => currency.code === editing?.currency);
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [state, setState] = useState({
    companyUserId: editing?.companyUser?.id ?? 0,
    creationDay: editing?.creationDay ?? '',
    usageDay: editing?.usageDay ?? '',
    permanent: editing?.expiresAt === '',
    expiresAt: editing?.expiresAt ?? '',
    currency: initialCurrency?.code ?? props.currencies[0].code,
    cost: initialCost ?? '',
    reason: editing?.reason ?? '',
    tags: (editing?.tags ?? []).reduce((tags, tag) => {
      tags[tag.tagsGroupId] = tag.id;
      return tags;
    }, {}),
    customFields: editing?.customFields ?? {},
    costCenters: (editing?.costCenters ?? []).map(costCenter => costCenter.id),
    validating: false,
    loading: false,
    success: false
  });

  const [requiredCustomFields, setRequiredCustomFields] = useState([]);

  const [requiredTagsGroups, setRequiredTagsGroups] = useState([]);

  const onChangeCustomField = (customFieldId, value) => {
    setState({ ...state, customFields: { ...state.customFields, [customFieldId]: value } });
  }

  const onFetchCustomFields = (customFields) => {
    const requiredCustomFields = customFields.filter(customField => customField.required);
    const ids = requiredCustomFields.map(customField => customField.id);
    setRequiredCustomFields(ids);
  }

  const onFetchTagsGroups = (tagsGroups) => {
    const requiredTagsGroups = tagsGroups.filter(tagsGroup => tagsGroup.required);
    const ids = requiredTagsGroups.map(tagsGroup => tagsGroup.id);
    setRequiredTagsGroups(ids);
  }

  const onSelectTag = (tagsGroupId, tag) => {
    setState({ ...state, tags: { ...state.tags, [tagsGroupId]: tag.id } });
  }

  const onSelectCostCenters = (costCenters) => {
    setState({ ...state, costCenters: costCenters });
  }

  const onChangeCompanyUserId = (value) => {
    setState({ ...state, companyUserId: value, tags: {}, customFields: {}, costCenters: [] });
    setRequiredCustomFields([]);
    setRequiredTagsGroups([]);
  }

  const onChangeCreationDay = (value) => {
    setState({ ...state, creationDay: value });
  }

  const onChangeUsageDay = (value) => {
    setState({ ...state, usageDay: value });
  }

  const onChangePermanent = (value) => {
    setState({ ...state, permanent: value });
  }

  const onChangeExpiresAt = (value) => {
    setState({ ...state, expiresAt: value });
  }

  const onChangeCurrency = (value) => {
    setState({ ...state, currency: value });
  }

  const onChangeCost = (value) => {
    setState({ ...state, cost: value });
  }

  const onChangeReason = (value) => {
    setState({ ...state, reason: value });
  }

  const setValidating = (value) => {
    setState({ ...state, validating: value });
  }

  const setLoading = (value) => {
    setState({ ...state, loading: value });
  }

  const setSuccess = (value) => {
    setState({ ...state, success: value });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateFields()) {
      setLoading(true);
      const dto = new AdvanceScheduleDto({ ...state });
      if (editing) {
        await updateAdvanceSchedule(
          editing.id,
          dto,
          () => setSuccess(true),
          () => setLoading(false),
        );
      } else {
        await createAdvanceSchedule(
          dto,
          () => setSuccess(true),
          () => setLoading(false),
        );
      }
    }
  }

  const validateFields = () => {
    setValidating(true);
    return validateUser() && validateDates() && validateCost() && validateReason() &&
      validateTags() && validateCustomFields() && validateCostCenters();
  }

  const validateReason = () => {
    return state.reason !== '';
  }

  const validateCost = () => {
    return state.currency !== '' && parseAmount(state.cost) !== 0;
  }

  const validateDates = () => {
    const expiresAt = dayjs(state.expiresAt);
    const initialExpiresAt = dayjs(editing?.expiresAt);
    const creationDate = creationDateBy(state.creationDay);
    return state.creationDay !== '' && state.usageDay !== '' &&
      validateExpirationBy(state.permanent, expiresAt, creationDate, editing, initialExpiresAt);
  }

  const validateUser = () => {
    return state.companyUserId !== 0;
  }

  const validateCostCenters = () => {
    return !(props.costAllocation?.required ?? false) || state.costCenters.length > 0;
  }

  const validateTags = () => {
    return requiredTagsGroups.every(id => state.tags[id]);
  }

  const validateCustomFields = () => {
    return requiredCustomFields.every(id => state.customFields[id]);
  }

  return <>
    <Card sx={{ p: 1, mb: 10 }}>
      <CardHeader
        title="Configuração de fundo fixo para adiantamento em espécie"
        subheader="Esse novo adiantamento não será submetido ao fluxo de aprovação e já será criado como aprovado."
      />
      <form onSubmit={handleSubmit}>
        <CardContent>
          <AdvanceScheduleUserField
            validating={state.validating}
            smallScreen={smallScreen}
            onChangeCompanyUserId={onChangeCompanyUserId}
            initialValue={editing?.companyUser}
          />
          <AdvanceScheduleDateField
            validating={state.validating}
            smallScreen={smallScreen}
            onChangeCreationDay={onChangeCreationDay}
            onChangeUsageDay={onChangeUsageDay}
            onChangePermanent={onChangePermanent}
            onChangeExpiresAt={onChangeExpiresAt}
            editing={editing}
            initialValue={{
              creationDay: editing?.creationDay,
              usageDay: editing?.usageDay,
              expiresAt: editing?.expiresAt,
            }}
          />
          <AdvanceScheduleCostField
            validating={state.validating}
            smallScreen={smallScreen}
            currencies={props.currencies}
            onChangeCurrency={onChangeCurrency}
            onChangeCost={onChangeCost}
            initialValue={{
              currency: initialCurrency?.code,
              cost: initialCost
            }}
          />
          {
            state.companyUserId !== 0 &&
            <AdvanceScheduleClassifications
              validating={state.validating}
              smallScreen={smallScreen}
              companyUserId={state.companyUserId}
              onFetchTagsGroups={onFetchTagsGroups}
              onSelectTag={onSelectTag}
              onFetchCustomFields={onFetchCustomFields}
              onChangeCustomField={onChangeCustomField}
              costAllocation={props.costAllocation}
              onSelectCostCenters={onSelectCostCenters}
              initialValue={{
                costCenters: editing?.costCenters,
                customFields: editing?.customFields,
                tags: editing?.tags,
              }}
            />
          }
          <AdvanceScheduleReasonField
            validating={state.validating}
            onChangeReason={onChangeReason}
            initialValue={editing?.reason}
          />
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={state.loading}
          >
            SALVAR
          </Button>
        </CardActions>
      </form>
    </Card>
    <Snackbar
      open={state.success}
      message={ editing ? "Fundo fixo atualizado com sucesso!" : "Fundo fixo criado com sucesso!" }
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    />
  </>
}

export default hot(module)(AdvanceScheduleConfig);