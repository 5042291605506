import { Avatar, Button, CardHeader, Dialog, DialogActions, DialogContent, Divider, InputAdornment, LinearProgress, ListItemAvatar, ListItemButton, ListItemText, Pagination, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchUsersBy } from "./advance_schedules_service";
import SearchIcon from '@mui/icons-material/Search';

const AdvanceScheduleUserModal = ({ open, onClose, onConfirm }) => {
  const [state, setState] = useState({
    loading: false,
    selected: null,
    data: [],
    pages: 1,
    search: '',
    error: false
  });

  const [triggers, setTriggers] = useState({
    page: 1,
    search: ''
  });

  const handleSelected = (selected) => {
    setState({ ...state, selected: selected });
  }

  const handlePage = (_, value) => {
    setTriggers({ ...triggers, page: value });
  }

  const handleSearch = (event) => {
    setState({ ...state, search: event.target.value });
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setTriggers({ ...triggers, page: 1, search: event.target.value });
    }
  }

  const fetchUsers = async () => {
    setState({ ...state, loading: true, error: false, selected: null });
    const body = await fetchUsersBy(triggers.page, triggers.search);
    if (body !== null) {
      setState({ ...state, loading: false, data: body.data, pages: body.pages, error: false });
    } else {
      setState({ ...state, loading: false, data: [], pages: 0, error: true });
    }
  }

  useEffect(() => {
    fetchUsers();
  }, [triggers]);

  return <Dialog
    open={open}
    maxWidth={"md"}
    fullWidth={true}
    disableAutoFocus={true}
    data-testid={"schedule-user-dialog"}
  >
    <CardHeader
      title={"Associar usuário"}
      titleTypographyProps={{ variant: "h6" }}
      sx={{ pb: 0, mx: 1 }}
    />
    <DialogContent>
      <TextField
        fullWidth
        sx={{ mb: 2 }}
        label="Usuário"
        placeholder="Buscar por nome ou e-mail do usuário"
        data-testid={"search-user-field"}
        value={state.search}
        onChange={handleSearch}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon/>
            </InputAdornment>
          ),
        }}
      />
      { state.loading && <LinearProgress sx={{ mt: 2 }}/> }
      { 
        state.error &&
        <Typography color={"error"}>
          Erro ao buscar usuários. Tente novamente.
        </Typography>
      }
      {
        state.data.map((user, index) => (
          <Stack key={index}>
            <ListItemButton
              selected={state.selected?.id == user.id}
              onClick={() => handleSelected(user)}
            >
              <ListItemAvatar
                children={<Avatar src={user.photo}/>}
              />
              <ListItemText
                primary={user.name}
                secondary={`(${user.email})`}
                secondaryTypographyProps={{ color: "black" }}
              />
            </ListItemButton>
            <Divider/>
          </Stack>
        ))
      }
      {
        state.pages > 0 &&
        <Stack
          alignItems={"end"}
          sx={{ mt: 2 }}
        >
          <Pagination
            shape="rounded"
            color="primary"
            count={state.pages}
            page={triggers.page}
            onChange={handlePage}
            data-testid={"pagination"}
          />
        </Stack>
      }
    </DialogContent>
    <DialogActions>
      <Button
        color={"inherit"}
        onClick={onClose}
        data-testid={"cancel-button"}
      >
        CANCELAR
      </Button>
      <Button
        disabled={!state.selected}
        onClick={() => onConfirm(state.selected)}
        data-testid={"confirm-button"}
      >
        ASSOCIAR
      </Button>
    </DialogActions>
  </Dialog>
}

export default AdvanceScheduleUserModal;