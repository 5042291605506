import React, {useState} from "react";
import {ErpTemplateConfig} from "../ErpTemplateConfig";
import {SankhyaProjectConfigSteps} from "./SankhyaProjectConfigSteps";
import {SankhyaProjectConfigForm} from "./SankhyaProjectConfigForm";
import {updateTemplate} from "../../erp_template_connector";

export const SankhyaProjectConfig = ({template}) => {
  const [field, setField] = useState(template?.settings?.field ?? '')
  const [value, setValue] = useState(template?.settings?.value ?? '')
  const [frequency, setFrequency] = useState(template?.frequency ?? 'daily')
  const [restricted, setRestricted] = useState(template?.settings?.restricted || false)

  const changeData = ({field, value, frequency, restricted}) => {
    setField(field)
    setValue(value)
    setFrequency(frequency)
    setRestricted(restricted)
  }

  return <ErpTemplateConfig
    erp={'sankhya'}
    title={'Configuração de projeto'}
    validConfig={field.length > 0 && value.length > 0}
    onConfigure={() => updateTemplate('sankhya', 'project', {field, value, restricted}, frequency)}
    steps={<SankhyaProjectConfigSteps/>}
    form={(loading, failed) => (
      <SankhyaProjectConfigForm
        data={{field, value, frequency, restricted}}
        onChangeData={changeData}
        failed={failed}
        disabled={loading}/>
    )}/>
}
