import React from "react";

import { InputAdornment, TextField } from "@mui/material";

import FileUploadIcon from "@mui/icons-material/FileUpload";

const SelectFileInput = ({ file, setFile }) => {
  const fileName = () => {
    if (file) {
      return file.name.length > 65
        ? file.name.substring(0, 65) + "..."
        : file.name;
    }
    return "Fazer upload do arquivo";
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <TextField
      data-testid="balance-file-input"
      sx={{
        mt: 1,
        width: "100%",
        maxWidth: "576px",
        "& input::file-selector-button": { display: "none" },
        "& input::file-selector-button:hover": { display: "none" },
        "& input": {
          color: "transparent",
          textIndent: "-9999px",
          cursor: "pointer",
        },
        "& input::before": { content: '""' },
      }}
      label={fileName()}
      placeholder={"select file"}
      variant="outlined"
      size="medium"
      type="file"
      InputLabelProps={{ shrink: false }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <FileUploadIcon />
          </InputAdornment>
        ),
        inputProps: {
          style: { appearance: "none", cursor: "pointer" },
        },
      }}
      onChange={handleFileChange}
    />
  );
};

export default SelectFileInput;
