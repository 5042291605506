import {Alert, AlertTitle, Button} from "@mui/material";
import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export const OmieWebhookInfo = () => {
  return <Alert
    severity={'info'}
    action={
      <Button
        size={'small'}
        color={'inherit'}
        variant={'outlined'}
        target={'_blank'}
        href={'https://developer.omie.com.br/my-apps/'}
        startIcon={<OpenInNewIcon/>}>
        Webhook
      </Button>
    }>
    <AlertTitle>
      Para configurar o webhook e concluir a integração, clique em “configurar
      webhook”.
    </AlertTitle>
    Você será direcionado para a página de configuração, onde poderá fazer os ajustes
    necessários de forma rápida e fácil.
  </Alert>
}
