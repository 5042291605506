import React from "react";
import { cpf } from "cpf-cnpj-validator";

import SelectTab from "../../shared/SelectTab";
import Masked from "../../shared/inputs/Masked";
import { initSelects, showSnackbar } from "../../shared/jquery_wrapper";
import Select from "../../shared/inputs/Select";
import {
  parserDate,
  parserCPF,
  validateDate,
  dateMask,
  cpfMask,
  phoneMask,
  parserPhone,
} from "../../shared/helpers";
import { Checkbox, FormControlLabel } from "@mui/material";

import {
  admin_companies_holder_index_path,
  bistro_accounts_company_holder_index_path,
} from "../../../../../app/javascript/routes";

class HoldersForm extends React.Component {
  state = {
    document: "",
    name: "",
    birth_date: "",
    email: "",
    phone: "",
    partner_type: "",
    principal: this.props.holders.length === 0,

    buttonClicked: "",
    id: "",

    holdersList: [],
    exibAllHolders: false,
    loading: false,
    holder_type: "physical",

    try_to_save: false,
  };

  componentDidMount() {
    const tempHolders = this.props.holders.filter(
      (e) => e.holder_type === "physical"
    );

    if (this.props.is_business) {
      this.setState({
        ...this.state,
        principal: tempHolders.length === 0,
        holdersList: tempHolders,
      });
    } else if (this.props.edit) {
      this.fillState({ holdersList: tempHolders, ...tempHolders[0] });
    } else {
      this.setState({
        ...this.state,
        principal: true,
        email: this.props.legal_infos.email,
        phone: parserPhone(this.props.legal_infos.phone),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.id !== this.state.id) {
      initSelects();
    }
  }

  render() {
    return (
      <div className="card-panel account-new-holders">
        <SelectTab
          list={[
            {
              testName: "pf-holder",
              label: "Dados do sócio pessoa física (PF)",
            },
          ]}
          customClass="tab-new-holder"
        />

        <div className="content">
          {this.props.is_business &&
            this.state.holdersList.filter(
              (e) => e.holder_type === this.state.holder_type
            ).length > 0 &&
            this.renderHolderList()}

          <form
            test-name="physical-form"
            className="form-design-on-surfaces d-flex justify-between flex-wrap"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <div className="d-flex justify-between full-width">
              <div>
                {this.props.is_business && (
                  <h5>
                    Cadastre os dados e adicione o número total de sócios da
                    empresa
                  </h5>
                )}
                <p>Informe os dados do sócio PF</p>
              </div>
              <div class="d-flex align-end" style={{ marginBottom: "16px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{
                        color:
                          this.state.holdersList.find(
                            (holder) => holder.principal
                          )?.kyc_status === "approved"
                            ? "rgba(0, 0, 0, 0.26)"
                            : "black",
                      }}
                      checked={
                        this.state.principal ||
                        this.state.holdersList.length === 0
                      }
                      disabled={
                        this.state.holdersList.length === 0 ||
                        this.state.holdersList.find(
                          (holder) => holder.principal
                        )?.kyc_status === "approved"
                      }
                      onClick={(e) =>
                        this.handleChange("principal", !this.state.principal)
                      }
                    />
                  }
                  label="Sócio principal (reponsável pelos cartões)"
                  labelPlacement="start"
                />
              </div>
            </div>
            <Masked
              required={true}
              error={this.invalidField("name")}
              forceError={this.invalidField("name") && this.state.try_to_save}
              test={"name"}
              size={65}
              placeholder={"Nome completo do sócio"}
              label={"Nome completo"}
              value={this.state.name}
              onChange={(e) => this.handleChange("name", e.target.value)}
            />
            <Masked
              required={true}
              error={this.invalidField("document")}
              forceError={
                this.invalidField("document") && this.state.try_to_save
              }
              test={"document"}
              size={30}
              placeholder={"000.000.000-00"}
              label={"CPF"}
              mask={cpfMask()}
              value={this.state.document}
              onChange={(e) => this.handleChange("document", e.target.value)}
            />
            <Masked
              required={true}
              error={this.invalidField("birth_date")}
              forceError={
                this.invalidField("birth_date") && this.state.try_to_save
              }
              test={"birth_date"}
              size={30}
              placeholder={"00/00/0000"}
              label={"Data de nascimento"}
              mask={dateMask()}
              value={this.state.birth_date}
              onChange={(e) => this.handleChange("birth_date", e.target.value)}
            />
            <Masked
              required={true}
              error={this.invalidField("email")}
              forceError={this.invalidField("email") && this.state.try_to_save}
              test={"email"}
              size={30}
              placeholder={"Insira o e-mail"}
              label={"E-mail"}
              type={"email"}
              value={this.state.email}
              onChange={(e) => this.handleChange("email", e.target.value)}
            />
            <Masked
              required={true}
              error={this.invalidField("phone")}
              forceError={this.invalidField("phone") && this.state.try_to_save}
              test={"phone"}
              size={30}
              placeholder={"+00 (00) 00000-0000"}
              label={"Telefone"}
              mask={phoneMask(this.state.phone)}
              value={this.state.phone}
              onChange={(e) => this.handleChange("phone", e.target.value)}
            />

            {this.props.is_business && (
              <Select
                required={true}
                error={
                  this.invalidField("partner_type") && this.state.try_to_save
                }
                test={"partner_type"}
                size={30}
                placeholder={"Selecione o tipo de sócio"}
                label={"Tipo de sócio"}
                options={[
                  { value: "partner", label: "Sócio" },
                  { value: "proxyholder", label: "Procurador" },
                  {
                    value: "legal_representative",
                    label: "Representante Legal",
                  },
                ]}
                value={this.state.partner_type}
                onChange={(e) =>
                  this.handleChange("partner_type", e.target.value)
                }
              />
            )}
            <div style={{ width: "30%" }} />
            <div className="d-flex justify-end full-width button-group">
              {this.props.is_business && this.renderSaveAddButton()}
              {this.renderContinueButton()}
              {this.renderReviewButton()}
            </div>
          </form>
        </div>
      </div>
    );
  }

  renderHolderList() {
    const list = this.state.holdersList.filter(
      (e) => e.holder_type === "physical"
    );

    const colorKycStatus = {
      not_sent: "Black",
      incomplete: "#C5BB61",
      in_analysis: "#6171C5",
      approved: "#61C5BB",
      reproved: "#C56171",
    };

    return (
      <React.Fragment>
        <div className="full-width holder-list-tittle">
          <h5>Sócios cadastrados</h5>
        </div>

        {list.map((holder, idx) =>
          idx <= 2 || this.state.exibAllHolders ? (
            <div
              key={idx}
              style={{ borderLeftColor: colorKycStatus[holder.kyc_status] }}
              className="collection z-depth-1 d-flex justify-between align-center holder-list-item"
            >
              <div>
                {holder.name}
                {holder.principal && (
                  <span>Sócio principal (reponsável pelos cartões)</span>
                )}
              </div>
              {holder.kyc_status !== "approved" && (
                <div>
                  <i
                    test-name={`delete-holder-${holder.id}`}
                    className="mdi mdi-delete icon delete"
                    onClick={() => this.handleDelete(holder.id)}
                  />
                  <i
                    data-testid={`edit-holder-${holder.id}`}
                    test-name={`edit-holder-${holder.id}`}
                    className="mdi mdi-pencil icon"
                    onClick={() => this.fillState(holder)}
                  />
                </div>
              )}
            </div>
          ) : (
            <React.Fragment key={idx} />
          )
        )}

        {list.length > 3 && (
          <p
            test-name="handle-see-all-holders"
            className="d-flex justify-center holder-list-more"
            onClick={() =>
              this.handleChange("exibAllHolders", !this.state.exibAllHolders)
            }
          >
            {this.state.exibAllHolders ? "Ver menos" : "Ver todos os"} sócios
            cadastrados{" "}
            <i
              className={`mdi mdi-chevron-${
                this.state.exibAllHolders ? "up" : "down"
              }`}
            />
          </p>
        )}
      </React.Fragment>
    );
  }

  renderSaveAddButton() {
    return (
      <button
        type="submit"
        test-name="save-add-button"
        className="btn"
        onClick={() => this.handleChange("buttonClicked", "add")}
        disabled={this.state.loading === true}
      >
        {this.state.loading ? "Salvando" : "Salvar e Adicionar novo Sócio PF"}
      </button>
    );
  }

  renderContinueButton() {
    return (
      <button
        type="submit"
        test-name="save-continue-button"
        className="btn save-continue-button"
        onClick={() => this.handleChange("buttonClicked", "continue")}
        disabled={this.state.loading === true}
      >
        {this.state.loading ? "Salvando" : "Salvar e Continuar"}
      </button>
    );
  }

  renderReviewButton() {
    return (
      this.state.holdersList.length > 0 && (
        <button
          test-name="go-review-button"
          className="btn review-button"
          onClick={(e) => {
            e.preventDefault();
            window.location.reload(false);
          }}
          disabled={this.state.loading}
        >
          {this.props.edit ? "Voltar para revisão" : "Ir para revisão"}
        </button>
      )
    );
  }

  clearState(customState) {
    const clear = {
      document: "",
      name: "",
      birth_date: "",
      partner_type: "",
      email: "",
      phone: "",
      principal: this.state.holdersList.length === 0,

      buttonClicked: "",
      id: "",

      try_to_save: false,
    };

    this.setState({ ...this.state, ...clear, ...customState });
  }

  fillState(data) {
    let infos = { ...data };
    delete infos.address;

    this.setState({
      ...this.state,
      ...infos,
      birth_date: parserDate(infos.birth_date),
      document: parserCPF(infos.document),
      email: infos.email || this.props.legal_infos.email,
      phone: parserPhone(infos.phone || this.props.legal_infos.phone),
    });
  }

  invalidField(checked) {
    const errors = {
      document: !cpf.isValid(cpf.strip(this.state.document)),
      birth_date: !validateDate(this.state.birth_date),
      name: this.state.name.length === 0,
      partner_type: this.props.is_business && this.state.partner_type === "",
      email: this.state.email.length === 0,
      phone: this.state.phone.length < 18,
    };

    return checked === "all"
      ? Object.values(errors).includes(true)
      : errors[checked];
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.invalidField("all")) {
      this.handleChange("try_to_save", true);

      showSnackbar({
        content: "Campos obrigatórios não preenchidos corretamente!",
        style: "alert",
      });

      return;
    }

    this.handleChange("loading", true);

    $.ajax({
      type: this.state.id === "" ? "POST" : "PUT",
      dataType: "json",
      url: this.submitEndpoint(),
      data: this.state,
    })
      .then((response) => {
        showSnackbar({ content: "Salvo com sucesso!", style: "notice" });

        if (this.state.buttonClicked === "add") {
          this.clearState({ principal: false });

          this.fecthHolders();
        } else {
          window.location.reload(false);
        }
      })
      .catch((error) => {
        showSnackbar({
          content: "Não foi possível salvar, tente novamente!",
          style: "alert",
        });
        this.handleChange("loading", false);
      });
  }

  handleDelete(id) {
    $.ajax({
      type: "DELETE",
      dataType: "json",
      url: `${this.props.endpoint.replace(
        "company_id",
        this.props.company_id
      )}/${id}`,
    })
      .then((response) => {
        showSnackbar({ content: "Excluído com sucesso!", style: "notice" });

        this.fecthHolders();
      })
      .catch((error) => {
        showSnackbar({
          content: "Não foi possível excluir, tente novamente!",
          style: "alert",
        });
      });
  }

  fecthHolders() {
    $.ajax({
      type: "GET",
      dataType: "json",
      url:
        this.props.usage === "admin"
          ? admin_companies_holder_index_path()
          : bistro_accounts_company_holder_index_path(this.props.company_id),
    })
      .then((response) => {
        const holders = response.data;

        this.setState({ ...this.state, holdersList: holders, loading: false });
      })
      .catch((error) => {
        showSnackbar({
          content: "Não foi possível carregar os sócios, tente novamente!",
          style: "alert",
        });
      });
  }

  submitEndpoint() {
    return this.state.id === ""
      ? this.props.endpoint.replace("company_id", this.props.company_id)
      : `${this.props.endpoint.replace("company_id", this.props.company_id)}/${
          this.state.id
        }`;
  }

  handleChange(proprety, value) {
    this.setState({ ...this.state, [proprety]: value });
  }
}

HoldersForm.defaultProps = {
  usage: "admin",
  company_id: "",
  endpoint: "",
  holders: [],
  edit: false,
  is_business: true,
  legal_infos: {},
};

export default HoldersForm;
