import React from "react";

class TermsAndPrivacyPolicy extends React.Component {
  state = { aceptTerms: false, aceptPrivacity: false };

  render() {
    return (
      <div className="account-new-terms-and-privacy-policy">
        <p className="section-tittle first">
          Antes de começar, confira as informações necessárias e aceite os
          Termos e Políticas de Privacidade.
        </p>
        <p>
          Para tornar seu cadastro mais rápido e eficiente, recomendamos que
          você tenha em mãos os seguintes dados:
        </p>

        <p className="section-tittle">Informações da Empresa</p>
        <ul>
          <li>CNPJ da empresa ou CPF (para Produtor Rural);</li>
          <li>Razão social (ou Nome do Produtor Rural);</li>
          <li>Data de fundação;</li>
          <li>Principal atividade da empresa;</li>
          <li>
            Tipo da empresa (MEI, EI, EIRELI, LTDA, Produtor Rural, etc.);
          </li>
          <li>
            Dados de todos os sócios PF (no caso da empresa ter mais de um);
          </li>
          <li>Dados de contato e endereço da empresa.</li>
        </ul>

        <p className="section-tittle">
          Informações dos Responsáveis legais (Sócio Pessoa Física - PF)
        </p>
        <ul>
          <li>Nome completo do responsável;</li>
          <li>Data de nascimento;</li>
          <li>CPF;</li>
          <li>E-mail do contato;</li>
          <li>Telefone de contato;</li>
        </ul>

        <div className="d-flex direction-column">
          <label className="label-checkbox">
            <input
              test-name="acept-terms"
              className="filled-in"
              type="checkbox"
              id="term-accept"
              checked={this.state.aceptTerms}
              onChange={() => this.handleCheckbox("aceptTerms")}
            />
            <span htmlFor="term-accept" />
            Aceito os{" "}
            <a
              href="https://www.espressoapp.com.br/termos-de-uso/"
              target="_blank"
            >
              Termos de Uso
            </a>
          </label>
          <label className="label-checkbox">
            <input
              test-name="acept-privacity"
              className="filled-in"
              type="checkbox"
              id="privacity-policy-accept"
              checked={this.state.aceptPrivacity}
              onChange={() => this.handleCheckbox("aceptPrivacity")}
            />
            <span htmlFor="privacity-policy-accept" />
            Aceito a{" "}
            <a
              href="https://www.espressoapp.com.br/termos-de-privacidade"
              target="_blank"
            >
              Politica de Privacidade
            </a>
          </label>
        </div>

        <div className="d-flex justify-end">
          <button
            test-name="start-button"
            className="btn start-button"
            disabled={!this.state.aceptTerms || !this.state.aceptPrivacity}
            onClick={() => {
              if (this.state.aceptTerms && this.state.aceptPrivacity) {
                this.props.setState({ stage: 2 });
              }
            }}
          >
            Iniciar cadastro
          </button>
        </div>
      </div>
    );
  }

  handleCheckbox(checkbox) {
    this.setState({ ...this.state, [checkbox]: !this.state[checkbox] });
  }
}
TermsAndPrivacyPolicy.defaultProps = {
  setState: Function,
};

export default TermsAndPrivacyPolicy;
