import {
    Avatar,
    Button,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Typography
} from "@mui/material";
import Paper from "@mui/material/Paper";
import React from "react";

function SingleItemSetting(props) {
    const {
        title,
        subtitle,
        itemTitle,
        itemSubtitle,
        actionLabel,
        onAction,
        actionHref,
        icon
    } = props;

    return <Stack gap={1}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1" color={'textSecondary'}>{subtitle} </Typography>
        <Paper sx={{mt: 2, mb: 4}}>
            <ListItem
                secondaryAction={
                    <Button variant="text" color="primary" href={actionHref} onClick={onAction}>
                        {actionLabel}
                    </Button>
                }>
                <ListItemAvatar>
                    <Avatar sx={{bgcolor: '#d2d3ff', color: '#6566a6'}}>
                        {icon}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={itemTitle}
                    secondary={itemSubtitle}/>
            </ListItem>
        </Paper>
    </Stack>
}


export default SingleItemSetting;