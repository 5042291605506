import React, {useCallback, useMemo, useState} from "react";

import {Grid, Stack, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {hot} from "react-hot-loader";
import {erps} from "../Index";
import EspressoTemplate from "../../EspressoTemplate";
import {PageNavbar} from "../../components/PageNavbar";
import {ErpIntegrationResume} from "./components/ErpIntegrationResume";
import {TemplateItem} from "./components/TemplateItem";
import {admin_erp_integration_path, admin_erp_integrations_path} from "../../../../routes";
import {activateConnection, deactivateConnection} from "../erp_integration_connector";
import {AsyncConfirmationDialog} from "../../../components/dialog/AsyncConfirmationDialog";
import {activateTemplate} from "./erp_template_connector";

const Index = (props) => {
    const {integration, templates, anyActive} = props;

    const configuredTemplates = useMemo(() => templates.map(template => ({
        template: template,
        type: templateTypes.find(type => type.identifier === template.templateType),
    })), [templates]);

    const availableTemplates = useMemo(() => {
        return templateTypes.filter(type => templates.every((template) => template.templateType !== type.identifier))
    }, [templates]);

    const [deactivating, setDeactivating] = useState(false)
    const [activating, setActivating] = useState(false)
    const [activatingTemplate, setActivatingTemplate] = useState(null)

    const erp = useMemo(() => {
        return erps.find(erp => erp.identifier === integration.erp)
    }, [integration]);

    const deactivate = useCallback(async () => {
        const success = await deactivateConnection(integration.erp)
        if (!success) {
            return 'Não foi possível desativar a integração'
        }
    }, [integration]);

    const activate = useCallback(async () => {
        const success = await activateConnection(integration.erp)
        if (!success) {
            return 'Não foi possível reativar a integração'
        }
    }, [integration]);

    const reactivateTemplate = useCallback(async () => {
        const success = await activateTemplate(integration.erp, activatingTemplate.templateType)
        if (!success) {
            return 'Não foi possível reativar o template'
        }
    }, [activatingTemplate]);

    return <EspressoTemplate {...props}>
        <Stack>
            <PageNavbar
                title={`Templates ${erp.title}`}
                backTo={admin_erp_integrations_path()}
                options={[
                    {
                        label: 'Desativar integração',
                        onClick: () => setDeactivating(true),
                        disabled: integration.status === 'inactive'
                    },
                    {
                        label: 'Editar configuração',
                        href: admin_erp_integration_path(integration.erp),
                        disabled: integration.status === 'inactive'
                    },
                    {
                        label: 'Reativar integração',
                        onClick: () => setActivating(true),
                        disabled: integration.status !== 'inactive' || anyActive
                    }
                ]}/>
            <Container maxWidth={'lg'} sx={{my: 5}}>
                <Grid container spacing={2} rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12}>
                        <ErpIntegrationResume integration={integration} erp={erp}/>
                    </Grid>
                    {configuredTemplates.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant={'h5'}>Configurados</Typography>
                        </Grid>
                    )}
                    {[...configuredTemplates.map(configured =>
                        <Grid item xs={12} md={6} key={configured.template.templateType}>
                            <TemplateItem
                                erp={erp.identifier}
                                integration={integration}
                                template={configured.template}
                                templateType={configured.type}
                                onActivate={() => setActivatingTemplate(configured.template)}/>
                        </Grid>
                    )]}
                    {availableTemplates.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant={'h5'}>Essas são suas opções</Typography>
                        </Grid>
                    )}
                    {[...availableTemplates.map((type, index) =>
                        <Grid key={`erp_${index}`} item xs={12} sm={6}>
                            <TemplateItem
                                erp={erp.identifier}
                                integration={integration}
                                templateType={type}/>
                        </Grid>
                    )]}
                </Grid>
            </Container>
            <AsyncConfirmationDialog
                open={!!activatingTemplate}
                onClose={() => setActivatingTemplate(null)}
                afterConfirm={() => window.location.reload()}
                confirmAction={reactivateTemplate}
                confirmLabel={'Reativar'}
                disableWith={'Reativando...'}
                confirmWith={'Reativado'}
                title={'Reativar template?'}
                content={'Tem certeza de que deseja reativar este template?'}/>
            <AsyncConfirmationDialog
                open={activating}
                onClose={() => setActivating(false)}
                confirmAction={activate}
                afterConfirm={() => window.location.reload()}
                confirmLabel={'Reativar'}
                disableWith={'Reativando...'}
                confirmWith={'Reativado'}
                title={'Reativar integração?'}
                content={'Tem certeza de que deseja reativar esta integração?'}/>
            <AsyncConfirmationDialog
                destructive={true}
                open={deactivating}
                onClose={() => setDeactivating(false)}
                confirmAction={deactivate}
                afterConfirm={() => window.location.reload()}
                confirmLabel={'Desativar'}
                disableWith={'Desativando...'}
                confirmWith={'Desativado'}
                title={'Tem certeza de que deseja desativar esta integração?'}
                content={'A integração será desativada e suas funções serão interrompidas.'}/>
        </Stack>
    </EspressoTemplate>
}

export const templateTypes = [
    {
        identifier: 'user',
        label: 'Usuários',
        available: true,
        about: {
            sankhya: 'A configuração permite realizar a criação e atualização de usuários no Espresso com base nos parceiros do ERP.',
            omie: 'A configuração permite realizar a criação e atualização de usuários no Espresso com base nos Clientes e Fornecedores do ERP.',
        },
        howTo: {
            sankhya: 'Informar o nome do campo personalizado e a rotina de sincronização.',
            omie: 'Para garantir que a integração com usuários no seu template funcione corretamente, é essencial configurar o webhook, cadastrar e inserir a tag do Espresso no ERP.\n' +
                'Isso permite que o sistema se comunique automaticamente com a sua plataforma, garantindo que todas as informações sejam atualizadas em tempo real.\n' +
                'Siga as instruções de configuração e ative o webhook agora para que sua integração funcione sem problemas.',
        }
    },
    {
        identifier: 'cost_center',
        label: 'Centros de custo',
        available: true,
        about: {
            sankhya: 'A configuração permite realizar a criação e atualização de centros de custos no Espresso com base nos centros de resultados do ERP.',
            omie: 'A configuração permite realizar a criação e atualização de centros de custos no Espresso com base nos departamentos do ERP.',
        },
        howTo: {
            sankhya: 'Informar o nome do campo personalizado e a rotina de sincronização.',
            omie: 'Para garantir que a integração com centros de custos no seu template funcione corretamente, é essencial configurar o webhook.\n' +
                'Isso permite que o sistema se comunique automaticamente com a sua plataforma, garantindo que todas as informações sejam atualizadas em tempo real.\n' +
                'Siga as instruções de configuração e ative o webhook agora para que sua integração funcione sem problemas.',
        }
    },
    {
        identifier: 'project',
        label: 'Projetos',
        available: true,
        about: {
            sankhya: 'A configuração permite realizar a criação e atualização de projetos no Espresso com base nos projetos do ERP.',
            omie: 'A configuração permite realizar a criação e atualização de projetos no Espresso com base nos projetos do ERP.',
        },
        howTo: {
            sankhya: 'Informar o nome do campo personalizado e a rotina de sincronização.',
            omie: 'Para garantir que a integração com projetos no seu template funcione corretamente, é essencial configurar o webhook.\n' +
                'Isso permite que o sistema se comunique automaticamente com a sua plataforma, garantindo que todas as informações sejam atualizadas em tempo real.\n' +
                'Siga as instruções de configuração e ative o webhook agora para que sua integração funcione sem problemas.',
        }
    },
    {
        identifier: 'advance',
        label: 'Pagamento de adiantamentos',
        available: false
    },
    {
        identifier: 'return',
        label: 'Devolução de adiantamentos',
        available: false
    },
    {
        identifier: 'accountability',
        label: 'Prestação de contas de adiantamentos',
        available: false
    },
    {
        identifier: 'reimbursement',
        label: 'Pagamento de reembolsos',
        available: false
    },
    {
        identifier: 'card_expense',
        label: 'Prestação de contas de despesas de cartão',
        available: false
    },
]

export default hot(module)(Index)