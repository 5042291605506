import { InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import AdvanceScheduleTagsGroupModal from "./AdvanceScheduleTagsGroupModal";
import { grayFocusedStyles } from "./AdvanceScheduleCostAllocationField";

const AdvanceScheduleTagsGroupField = ({ validating, tagsGroup, onSelect, initialValue }) => {
  const [state, setState] = useState({
    tag: initialValue ?? null,
    opened: false
  });

  const onOpen = () => {
    setState({ ...state, opened: true });
  }

  const onClose = () => {
    setState({ ...state, opened: false });
  }

  const handleSelect = (tag) => {
    setState({ ...state, opened: false, tag: tag });
    onSelect(tag);
  }

  const error = validating && tagsGroup.required && state.tag == null;  

  return <>
    <AdvanceScheduleTagsGroupModal
      tagsGroup={tagsGroup}
      open={state.opened}
      onClose={onClose}
      onSelect={handleSelect}
    />
    <TextField
      fullWidth
      onClick={onOpen}
      label={tagsGroup.name}
      value={state.tag?.name || ''}
      error={error}
      helperText={ error && "Esse campo é obrigatório" }
      data-testid={"tags-group-field"}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      focused={true}
      sx={grayFocusedStyles}
      placeholder={"Selecione uma tag do grupo"}
    />
  </>
}

export default AdvanceScheduleTagsGroupField;