import React, { useState } from "react";
import { Alert, AlertTitle, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { creationDateBy, expiresAtErrorMessage, validateExpirationBy } from "./advance_schedules_service";

const defaultMinExpirationDate = dayjs().add(1, "day").startOf("day");

const AdvanceScheduleDateField = ({
  validating, smallScreen, onChangeCreationDay, onChangeUsageDay, onChangePermanent, onChangeExpiresAt,
  editing, initialValue
}) => {
  const [state, setState] = useState({
    creationDay: initialValue?.creationDay ?? '',
    creationDate: creationDateBy(initialValue?.creationDay),
    usageDay: initialValue?.usageDay ?? '',
    permanent: initialValue?.expiresAt === '',
    expiresAt: initialValue?.expiresAt ?? ''
  });

  const days = Array.from({ length: 28 }, (_, index) => index + 1);

  const handleCreationDay = (event) => {
    const creationDay = event.target.value;
    setState({
      ...state,
      creationDay: creationDay,
      creationDate: creationDateBy(creationDay),
    });
    onChangeCreationDay(creationDay);
  }

  const handleUsageDay = (event) => {
    const usageDay = event.target.value;
    setState({ ...state, usageDay: usageDay });
    onChangeUsageDay(usageDay);
  }

  const handlePermanent = (event) => {
    const permanent = event.target.checked;
    setState({ ...state, permanent: permanent });
    onChangePermanent(permanent);
  }

  const minExpirationDate = state.creationDate ?? defaultMinExpirationDate;

  const handleExpiresAt = (value) => {
    const expiresAt = dayjs(value).format('YYYY-MM-DD');
    setState({ ...state, expiresAt: expiresAt });
    onChangeExpiresAt(expiresAt);
  }

  const creatinDayError = validating && state.creationDay === '';

  const usageDayError = validating && state.usageDay === '';

  const expiresAt = dayjs(state.expiresAt);
  const initialExpiresAt = dayjs(initialValue?.expiresAt);
  const expiresAtError = validating && !state.permanent &&
    !validateExpirationBy(state.permanent, expiresAt, state.creationDate, editing, initialExpiresAt);

  return <Grid
    container
    mt={2}
    columnSpacing={2}
    data-testid={"advance-schedule-date-field"}
  >
    <Grid item xs={12}>
      <Typography variant="h6">
          2. Recorrência
      </Typography>
    </Grid>
    {
      editing &&
      <Grid item xs={12} my={1}>
        <Alert
          severity="info"
          data-testid={"editing-alert"}
        >
          <AlertTitle>Importante:</AlertTitle>
          A alteração será aplicada ao mês vigente se realizada antes da data configurada para o registro do adiantamento. Caso a modificação ocorra na data configurada ou após, a atualização será válida somente a partir do próximo mês.
        </Alert>
      </Grid>
    }
    <Grid item xs={12} mt={1}>
      <Typography variant="body1" color={"GrayText"}>
        Recorrência mensal, às 07:00 definida
      </Typography>
    </Grid>
    <Grid item xs={ smallScreen ? 12 : 6 } mt={2}>
      <FormControl
        fullWidth
        error={creatinDayError}
      >
        <InputLabel id={"creation-day-select-label"}>Dia do registro do adiantamento</InputLabel>
        <Select
          value={state.creationDay}
          onChange={handleCreationDay}
          labelId={"creation-day-select-label"}
          label={"Dia do registro do adiantamento"}
        >
          {
            days.map((day, index) => (
              <MenuItem key={index} value={day}>
                {day}
              </MenuItem>
            ))
          }
        </Select>
        {
          creatinDayError &&
          <FormHelperText>
            Esse campo é obrigatório
          </FormHelperText>
        }
      </FormControl>
    </Grid>
    <Grid item xs={ smallScreen ? 12 : 6 } mt={2}>
      <FormControl
        fullWidth
        error={usageDayError}
      >
        <InputLabel id={"usage-day-select-label"}>Dia de utilização do adiantamento</InputLabel>
        <Select
          value={state.usageDay}
          onChange={handleUsageDay}
          labelId={"usage-day-select-label"}
          label={"Dia de utilização do adiantamento"}
        >
          {
            days.map((day, index) => (
              <MenuItem key={index} value={day}>
                {day}
              </MenuItem>
            ))
          }
        </Select>
        {
          usageDayError &&
          <FormHelperText>
            Esse campo é obrigatório
          </FormHelperText>
        }
      </FormControl>
    </Grid>
    <Grid item xs={12} mt={ smallScreen ? 2 : 1 } mb={ smallScreen && 1 }>
      <FormControlLabel
        label="Não definir data de encerramento da recorrência"
        control={
          <Checkbox
            checked={state.permanent}
            onChange={handlePermanent}
            data-testid={"permanent-checkbox"}
          />
        }
      />
    </Grid>
    <Grid item xs={12} mt={2}>
      <FormControl fullWidth>
        <LocalizationProvider
          adapterLocale="pt-br"
          dateAdapter={AdapterDayjs}
        >
          <DatePicker
            disablePast
            disableHighlightToday
            disabled={state.permanent}
            minDate={minExpirationDate}
            label="Data de encerramento da recorrência"
            format={"DD/MM/YYYY"}
            value={expiresAt}
            onChange={handleExpiresAt}
            slots={{ openPickerIcon: ArrowDropDownIcon }}
            slotProps={{
              textField: {
                error: expiresAtError,
                helperText: expiresAtError && expiresAtErrorMessage(
                  expiresAt, minExpirationDate, editing?.expired ?? false
                )
              }
            }}
          />
        </LocalizationProvider>
      </FormControl>
    </Grid>
  </Grid>;
}

export default AdvanceScheduleDateField;