import moment from "moment";

export function downloadFile(blob, fileName) {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.style.cssText = "display: none";
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url)
}

export function parserPhone(phone) {
  let i = 0;
  return (
    phone.length === 13 ? "+## (##) #####-####" : "+## (##) ####-####"
  ).replace(/#/g, () => phone[i++] || "");
}

export function parserPhoneWithoutCountryCode(phone) {
  let i = 0;
  return (
    phone.length === 13 ? "(##) #####-####" : "(##) ####-####"
  ).replace(/#/g, () => phone[i++] || "");
}

export function parserDate(date) {
  return moment(date).format("DD/MM/YYYY");
}

export function parserDateTime(date) {
  return moment(date).format("DD/MM/YYYY HH:mm:ss");
}

export function parserPostalCode(postal_code) {
  let i = 0;
  return "##.###-###".replace(/#/g, () => postal_code[i++]);
}

export function parserCNPJ(cnpj) {
  let i = 0;
  return "##.###.###/####-##".replace(/#/g, () => cnpj[i++]);
}

export function parserMainActivity(main_activity) {
  let i = 0;
  return "##.##-#-##".replace(/#/g, () => main_activity[i++]);
}

export function parserCPF(cpf) {
  let i = 0;
  return "###.###.###/##".replace(/#/g, () => cpf[i++]);
}

export function parserCurrencyBRLValue(value, withCurrency = true) {
  const real = Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  if (withCurrency) {
    return real.format(value);
  }

  return real.format(value).replace(/^R\$\s?/, '');;
}

export function parserNumber(value, minimunDecimals) {
  return Intl.NumberFormat("pt-BR", {
    minimumFractionDigits: minimunDecimals || "auto",
  }).format(value);
}

function formatValueWithBrlCurrency(value, allowZero = false) {
  let tmpValue = String(value).replace(/\D/g, "");

  if (!Number(tmpValue) && !allowZero) {
    return "0.00";
  } else if (!Number(tmpValue) && allowZero) {
    return "";
  } else if (tmpValue.length === 1) {
    tmpValue = `0.0${tmpValue}`;
  } else {
    tmpValue =
      tmpValue.split("").slice(0, -2).join("") +
      "." +
      tmpValue.split("").slice(-2).join("");
  }

  return tmpValue;
}

export function parserValueWithoutBrlCurrency(value) {
  const formattedValue = formatValueWithBrlCurrency(value);
  return parserCurrencyBRLValue(formattedValue, false);
}

export function parserValueWithBrlCurrency(value) {
  const formattedValue = formatValueWithBrlCurrency(value);
  return parserCurrencyBRLValue(formattedValue);
}

export function parserValueWithBrlCurrencyAllowZero(value) {
  const formattedValue = formatValueWithBrlCurrency(value, true);
  return parserCurrencyBRLValue(formattedValue);
}

export function parserHolderType(type) {
  return {
    partner: "Sócio",
    proxyholder: "Procurador",
    legal_representative: "Representante Legal",
  }[type];
}

export function parser_resume_address(
  street,
  number,
  complement,
  postal_code,
  neighborhood,
  city,
  state
) {
  return (
    `${street} ${number} ${complement}- ${parserPostalCode(postal_code)}, ` +
    `${neighborhood}, ${city}/${state}`
  );
}

export function brazilStates() {
  return [
    { value: "AC", label: "AC" },
    { value: "AL", label: "AL" },
    { value: "AP", label: "AP" },
    { value: "AM", label: "AM" },
    { value: "BA", label: "BA" },
    { value: "CE", label: "CE" },
    { value: "DF", label: "DF" },
    { value: "ES", label: "ES" },
    { value: "GO", label: "GO" },
    { value: "MA", label: "MA" },
    { value: "MT", label: "MT" },
    { value: "MS", label: "MS" },
    { value: "MG", label: "MG" },
    { value: "PA", label: "PA" },
    { value: "PB", label: "PB" },
    { value: "PR", label: "PR" },
    { value: "PE", label: "PE" },
    { value: "PI", label: "PI" },
    { value: "RJ", label: "RJ" },
    { value: "RN", label: "RN" },
    { value: "RS", label: "RS" },
    { value: "RO", label: "RO" },
    { value: "RR", label: "RR" },
    { value: "SC", label: "SC" },
    { value: "SP", label: "SP" },
    { value: "SE", label: "SE" },
    { value: "TO", label: "TO" },
  ];
}

export function validateDate(date) {
  const match = /^(\d{2})\/(\d{2})\/(\d{4})$/.exec(date);

  if (match) {
    const dia = parseInt(match[1]);
    const mes = parseInt(match[2]);
    const ano = parseInt(match[3]);

    const d = new Date(ano, mes - 1, dia);
    const dataValida =
      d.getFullYear() == ano && d.getMonth() + 1 == mes && d.getDate() == dia;

    return dataValida;
  } else {
    return false;
  }
}

export function cnpjMask() {
  return [
    /[0-9]/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];
}

export function dateMask() {
  return [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
}

export function MainActivityMask() {
  return [/[0-9]/, /\d/, ".", /\d/, /\d/, "-", /\d/, "-", /\d/, /\d/];
}

export function postal_codeMask() {
  return [/[0-9]/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];
}

export function phoneMask(phone) {
  const middle = phone.length === 19 ? [/\d/, "-"] : ["-", /\d/];

  return [
    "+",
    /\d/,
    /\d/,
    " ",
    "(",
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ...middle,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
}

export function cpfMask() {
  return [
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];
}

export function cardNumberMask() {
  return [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
}
