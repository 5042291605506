import { post } from "@rails/request.js";
import {
  balance_admin_account_sub_accounts_multi_balances_path,
  balance_file_admin_account_sub_accounts_multi_balances_path,
} from "../../routes";

export const parseAmount = (amount) => Number(amount.replace("R$", "").replace(/\./g, "").replace(",", "."));

export const calcWithdraw = (balanceAccount, numberAmount, subAccounts, withdrawAll) => {
  let totalAdded = 0;
  subAccounts.forEach(subAccount => {
    const rescueAmount = withdrawAll ? subAccount.balance : Math.min(subAccount.balance, numberAmount);
    totalAdded += rescueAmount;
  });
  return Number(balanceAccount) + totalAdded;
};

export const calcNewBalance = (operation, subAccounts, numberAmount, balanceAccount, withdrawAll) => {
  const options = {
    'adjust': subAccounts.reduce((acc, { balance }) => acc + balance - numberAmount, Number(balanceAccount)),
    'deposit': (Number(balanceAccount) - (numberAmount * subAccounts.length)),
    'withdraw': calcWithdraw(balanceAccount, numberAmount, subAccounts, withdrawAll)
  };
  return options[operation];
};

const dataPostAdjuste = (amount, subAccountIds, operation) => {
  return {
    amount: amount,
    sub_account_ids: subAccountIds,
    operation: operation,
  };
};

export const postAdjuster = async (amount, subAccountIds, operation) => {
  try {
    return await post(
      balance_admin_account_sub_accounts_multi_balances_path(),
      {
        body: JSON.stringify(dataPostAdjuste(amount, subAccountIds, operation)),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const postFileAdjuster = async (file, operation) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("operation", operation);

  try {
    return await $.ajax({
      type: "POST",
      url: balance_file_admin_account_sub_accounts_multi_balances_path(),
      data: formData,
      processData: false,
      contentType: false,
    });
  } catch (error) {
    throw error;
  }
}
