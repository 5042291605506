import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Pagination,
    Skeleton,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import {FormattedMessage as Intl, useIntl} from 'react-intl';

const SelectModal = (props) => {
    const {
        open,
        title,
        selectAllTitle,
        allExcept,
        selected,
        onClose,
        onConfirm,
        fetchItems,
        errorMessage
    } = props;

    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState(selected);
    const [search, setSearch] = useState('');
    const [selectAll, setSelectAll] = useState(allExcept);

    const [pageState, setPageState] = useState({
        filtered: false,
        data: [],
        totalPages: 1,
        totalCount: 0,
        page: 1,
        error: false
    })

    const handlePage = (_, page) => loadItems(page);

    const handleSelect = (item) => {
        if (selectedItems.includes(item.id)) {
            setSelectedItems(selectedItems.filter(selected => selected !== item.id));
        } else {
            setSelectedItems([...selectedItems, item.id]);
        }
    }

    const handleSelectAll = (event) => {
        setSelectedItems([]);
        setSelectAll(event.target.checked);
    }

    const loadItems = async (page) => {
        setLoading(true);
        const body = await fetchItems(page, search);
        setLoading(false);
        setPageState({
            ...pageState,
            page: page,
            filtered: search.length > 0,
            data: body?.data || [],
            totalPages: body?.totalPages || 0,
            totalCount: body?.totalCount || 0,
            error: body === null
        });
    }

    useEffect(() => {
        if (open) loadItems(1)
    }, [open]);

    return <Dialog open={open} maxWidth={"sm"} fullWidth={true}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            {(pageState.totalPages > 1 || pageState.filtered) && (
                <SearchField search={search} onChange={setSearch} onEnter={() => loadItems(1)}/>
            )}
            {loading &&
                <LoadingState/>
            }
            {pageState.error && (
                <Typography color={"error"}>{errorMessage}</Typography>)
            }
            {!pageState.error && !loading && (
                <ItemList
                    data={pageState.data}
                    selectedItems={selectedItems}
                    onSelect={handleSelect}
                    selectAll={selectAll}
                    selectAllTitle={selectAllTitle}
                    totalCount={pageState.totalCount}
                    onSelectAll={handleSelectAll}/>
            )}
            <Stack alignItems={"end"} sx={{mt: 2}}>
                <Pagination
                    disabled={loading || pageState.totalPages < 2}
                    shape="rounded"
                    color="primary"
                    count={pageState.totalPages}
                    page={pageState.page}
                    onChange={handlePage}
                    data-testid={"pagination"}/>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button color={"inherit"} onClick={onClose}>CANCELAR</Button>
            <Button onClick={() => onConfirm(selectAll, selectedItems)}>CONFIRMAR</Button>
        </DialogActions>
    </Dialog>
}

function SearchField({onEnter, search, onChange}) {
    const intl = useIntl();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onEnter();
        }
    }

    return <TextField
        fullWidth
        sx={{my: 2}}
        label={<Intl id={"classification.modal.search_label"}/>}
        placeholder={intl.$t({id: "classification.modal.search_placeholder"})}
        value={search}
        onChange={(event) => onChange(event.target.value)}
        onKeyDown={handleKeyDown}
        InputProps={{
            endAdornment: <InputAdornment position="end">
                <SearchIcon/>
            </InputAdornment>
        }}
    />
}

function ItemList(props) {
    const {
        data,
        selectedItems,
        onSelect,
        selectAll,
        selectAllTitle,
        totalCount,
        onSelectAll
    } = props;

    const allSelected = selectAll && selectedItems.length === 0 || !selectAll && selectedItems.length === totalCount;

    return (<>
        <Paper variant={'outlined'} sx={{px: 1}}>
            <FormControlLabel
                label={selectAllTitle}
                control={<Checkbox
                    checked={allSelected}
                    indeterminate={selectedItems.length > 0 && selectedItems.length < totalCount}
                    onChange={onSelectAll}/>}/>
        </Paper>
        <List sx={{minHeight: 310}}>
            {data.map(item => (
                <ListItemButton divider key={item.id} onClick={() => onSelect(item)}>
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            inputProps={{ 'aria-label': item.name }}
                            checked={!!(selectAll ^ selectedItems.includes(item.id))}
                            tabIndex={-1}
                            disableRipple/>
                    </ListItemIcon>
                    <ListItemText primary={item.name}/>
                </ListItemButton>
            ))}
        </List>
    </>)
}

function LoadingState() {
    return <>
        <Paper variant={'outlined'} sx={{px: 1}}>
            <FormControlLabel
                label={<Skeleton variant="text" width={300}/>}
                control={<Skeleton variant={'rounded'} width={26} height={26} sx={{m: 1}}/>}/>
        </Paper>
        <List>
            {[...Array(5).fill(0).map((_, index) => (<LoadingItem key={index}/>))]}
        </List>
    </>
}

function LoadingItem() {
    return <ListItem divider>
        <ListItemIcon>
            <Skeleton variant={'rounded'} width={26} height={26} sx={{my: 1}}/>
        </ListItemIcon>
        <ListItemText primary={<Skeleton variant="text" width={300}/>}/>
    </ListItem>
}

export default SelectModal;