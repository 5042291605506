import React from "react";
import { Container, Stack, useMediaQuery } from "@mui/material";
import AdvanceScheduleListItem from "./AdvanceScheduleListItem";
import EspressoTemplate from "../EspressoTemplate";
import { PageNavbar } from "../components/PageNavbar";
import { admin_advance_schedules_path } from "../../../routes";
import { hot } from "react-hot-loader";

const AdvanceSchedule = (props) => {
  return <EspressoTemplate {...props}>
    <Stack>
      <PageNavbar
        showBackButton={true}
        title={"Fundo fixo"}
        backTo={admin_advance_schedules_path()}
      />
      <Container maxWidth={"lg"} sx={{ mt: 5 }}>
        <AdvanceScheduleContent {...props}/>
      </Container>
    </Stack>
  </EspressoTemplate>
}

const AdvanceScheduleContent = (props) => {
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return <AdvanceScheduleListItem
    schedule={props}
    smallScreen={smallScreen}
    onRefresh={() => window.location.reload()}
    expanded={true}
  />
}

export default hot(module)(AdvanceSchedule);