import {Alert, AlertTitle} from "@mui/material";
import React from "react";

export const OmieProjectConfigSteps = ({webhooksUrl}) => {
  return <>
    <p><b>Passo 1: </b>Acessar o link de configuração</p>
    <ul>
      <li>Link: <a href="https://developer.omie.com.br/my-apps/"
                   target="_blank">https://developer.omie.com.br/my-apps/</a></li>
    </ul>
    <p><b>Passo 2: </b>Clique na conta que deseja configurar e adicione um novo Webhook</p>
    <ul>
      <li>Selecione a conta que deseja configurar o aplicativo.</li>
      <li>
        Ao selecionar a conta da empresa você precisar clicar em “Adicionar Novo Webhook”.
      </li>
    </ul>
    <p><b>Passo 3: </b>Insira o link no campo “Endpoint”</p>
    <ul>
      <li>Link: <a
        href={webhooksUrl}
        target="_blank">{webhooksUrl}</a>
      </li>
    </ul>
    <p><b>Passo 4: </b>Selecionar os campos abaixo referentes à integração</p>
    <ul>
      <li>Projeto Alterado</li>
      <li>Projeto Incluído</li>
      <li>Projeto Excluído</li>
    </ul>
    <p><b>Passo 5: </b>Salvar configuração</p>
    <ul>
      <li>
        Após inserir o link de “Endpoint” e selecionar os campos referentes á integração,
        clique em “Salvar”.
      </li>
    </ul>
    <Alert sx={{mt: 2}} severity="info">
      <AlertTitle>
        Depois de salvo, é preciso retornar para a tela inicial.
      </AlertTitle>
      Assim que clicar na conta da empresa novamente, o Webhook configurado estará
      visível.
    </Alert>
  </>
}