import React, {useMemo, useState} from "react";
import SingleItemSetting from "../../../components/SingleItemSetting";
import SellIcon from "@mui/icons-material/Sell";
import {FormattedMessage as Intl} from "react-intl";
import SelectModal from "../../../components/SelectModal";
import {restrictCategory} from "../../../classifications_service";

function ClassificationRestrictionCard(props) {
    const {categoryId, subcategoryId, restriction, type, fetchItems, onError, searchError} = props;

    const [open, setOpen] = useState(false)

    const onConfirm = async (allExcept, classifications) => {
        const success = await restrictCategory(categoryId, subcategoryId, type, allExcept, classifications,);
        if (success) {
            window.location.reload();
        } else {
            onError();
        }
    }

    const selected = useMemo(() => {
        if (restriction.restrictive) {
            return restriction.classifications.map(classification => classification.id);
        }
        return restriction.inaccessible.map(classification => classification.id);
    }, [restriction]);

    const level = subcategoryId ? 'subcategory' : 'category';
    const title = itemTitle(restriction);
    const subtitle = itemSubtitle(type, restriction);

    return (
        <>
            <SelectModal
                open={open}
                selected={selected}
                onConfirm={onConfirm}
                errorMessage={searchError}
                allExcept={!restriction.restrictive}
                onClose={() => setOpen(false)}
                title={<Intl id={`restriction.${type}_card.title`}/>}
                selectAllTitle={<Intl id={`restriction.select_all`}/>}
                fetchItems={(page, search) => fetchItems(page, search)}/>
            <SingleItemSetting
                icon={<SellIcon/>}
                title={<Intl id={`restriction.${type}_card.title`}/>}
                subtitle={<Intl id={`restriction.${type}_card.subtitle.${level}`}/>}
                itemTitle={<Intl id={`restriction.${type}_card.item_title.${title}`}/>}
                itemSubtitle={subtitle}
                actionLabel={<Intl id={`restriction.${type}_card.action`}/>}
                onAction={() => setOpen(true)}/>
        </>
    )
}

const itemTitle = (restriction) => {
    if (!restriction.restrictive && restriction.inaccessible?.length === 0) {
        return 'all';
    }
    if (restriction.restrictive && restriction.classifications.length === 0) {
        return 'none';
    }
    return 'selected';
}

const itemSubtitle = (type, restriction) => {
    if (!restriction.restrictive && restriction.inaccessible.length === 0) {
        return <Intl id={`restriction.${type}_card.item_subtitle.all`}/>;
    }
    if (!restriction.restrictive && restriction.inaccessible.length > 0) {
        const except = classificationList(type, restriction.inaccessible);
        return <Intl id={`restriction.except`} values={{except}}/>;
    }
    if (restriction.classifications.length === 0) {
        return <Intl id={`restriction.${type}_card.item_subtitle.none`}/>;
    }
    return classificationList(type, restriction.classifications);
}

const classificationList = (type, collection) => {
    if (collection.length < 3) {
        return collection.map((item) => item.name).join(' e ');
    }
    const count = collection.length - 1;
    const first = collection[0].name;
    return <Intl id={`restriction.${type}_card.item_subtitle.selected`} values={{count, first}}/>;
}

export default ClassificationRestrictionCard;