import React, { useEffect, useState } from "react";
import { Button, CardHeader, Dialog, DialogActions, DialogContent, Divider, InputAdornment, LinearProgress, ListItemButton, Pagination, Stack, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { fetchCostCentersBy } from "./advance_schedules_service";

const MAX_COST_CENTERS = 4;

const AdvanceScheduleCostAllocationModal = ({
  open, title, selected, companyUserId, onClose, onConfirm, onChangeAnyAccessibleCostCenter
}) => {
  const [state, setState] = useState({
    loading: false,
    selected: [],
    data: [],
    pages: 1,
    search: '',
    error: false,
  });

  const [triggers, setTriggers] = useState({
    page: 1,
    search: ''
  });

  const handleSearch = (event) => {
    setState({ ...state, search: event.target.value });
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setTriggers({ ...triggers, page: 1, search: event.target.value });
    }
  }

  const handlePage = (_, value) => {
    setTriggers({ ...triggers, page: value });
  }

  const handleSelect = (costCenter) => {
    if (state.selected.some(selected => selected.id === costCenter.id)) {
      setState({ ...state, selected: state.selected.filter(selected => selected.id !== costCenter.id) });
    } else if (state.selected.length < MAX_COST_CENTERS) {
      setState({ ...state, selected: [...state.selected, costCenter] });
    }
  }

  const fetchCostCenters = async () => {
    setState({ ...state, loading: true, error: false });
    const body = await fetchCostCentersBy(companyUserId, triggers.page, triggers.search);
    const data = body?.data ?? [];
    setState({
      ...state,
      loading: false,
      data: data,
      pages: body?.pages ?? 0,
      error: body === null
    });
    if (triggers.page === 1 && triggers.search === '') {
      onChangeAnyAccessibleCostCenter(data.length > 0);
    }
  }

  const updateSelected = () => {
    setState({ ...state, selected: selected });
  }

  useEffect(() => {
    fetchCostCenters();
  }, [triggers]);

  useEffect(() => {
    updateSelected();
  }, [selected]);

  return <Dialog
    open={open}
    maxWidth={"md"}
    fullWidth={true}
    disableAutoFocus={true}
  >
    <CardHeader
      title={title}
      titleTypographyProps={{ variant: "h6" }}
      sx={{ pb: 0, mx: 1 }}
    />
    <DialogContent>
      <TextField
        fullWidth
        sx={{ mb: 2 }}
        label="Buscar"
        placeholder="Busque pelo centro de custos"
        data-testid={"search-cost-center-field"}
        value={state.search}
        onChange={handleSearch}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon/>
            </InputAdornment>
          ),
        }}
      />
      <Typography align="right" mb={1}>
        {`Selecionados: ${state.selected.length}/${MAX_COST_CENTERS}`}
      </Typography>
      { 
        state.loading && 
        <LinearProgress sx={{ mt: 2 }}/>
      }
      { 
        state.error &&
        <Typography color={"error"}>
          Erro ao buscar centros de custo. Tente novamente.
        </Typography>
      }
      {
        state.data.map((costCenter, index) => (
          <Stack key={index}>
            <ListItemButton
              selected={state.selected.some(selected => selected.id === costCenter.id)}
              onClick={() => handleSelect(costCenter)}
            >
              {costCenter.name}
            </ListItemButton>
            <Divider/>
          </Stack>
        ))
      }
      {
        state.pages > 0 &&
        <Stack
          alignItems={"end"}
          sx={{ mt: 2 }}
        >
          <Pagination
            shape="rounded"
            color="primary"
            count={state.pages}
            page={triggers.page}
            onChange={handlePage}
            data-testid={"pagination"}
          />
        </Stack>
      }
    </DialogContent>
    <DialogActions>
      <Button
        color={"inherit"}
        onClick={onClose}
        data-testid={"cancel-button"}
      >
        CANCELAR
      </Button>
      <Button
        disabled={state.selected.length === 0}
        onClick={() => onConfirm(state.selected)}
        data-testid={"confirm-button"}
      >
        CONFIRMAR
      </Button>
    </DialogActions>
  </Dialog>
}

export default AdvanceScheduleCostAllocationModal;