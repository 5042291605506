import React, {useState} from "react";
import {ErpTemplateConfig} from "../ErpTemplateConfig";
import {OmieUserConfigSteps} from "./OmieUserConfigSteps";
import {OmieUserConfigForm} from "./OmieUserConfigForm";
import {OmieWebhookInfo} from "./OmieWebhookInfo";
import {updateTemplate} from "../../erp_template_connector";

export const OmieUserConfig = ({integration, template}) => {
    const [tag, setTag] = useState(template?.settings?.tag ?? '')

    return <ErpTemplateConfig
        erp={'omie'}
        title={'Configuração de usuários'}
        validConfig={tag.length > 0}
        onConfigure={() => updateTemplate('omie', 'user', {tag})}
        info={<OmieWebhookInfo/>}
        steps={<OmieUserConfigSteps webhooksUrl={integration.webhooksUrl}/>}
        form={(loading, failed) => (
            <OmieUserConfigForm
                tag={tag}
                failed={failed}
                disabled={loading}
                onChangeTag={setTag}/>
        )}/>
}
