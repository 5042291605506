import React, { useState } from "react";
import { Button, Card, CardActions, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';

const AdvanceScheduleFilter = ({ smallScreen, onFilter, onClean }) => {
  const [state, setState] = useState({
    visible: false,
    search: '',
    status: '',
  });

  const toggleVisible = () => {
    setState({ ...state, visible: !state.visible });
  }

  const handleClean = () => {
    setState({ ...state, search: '', status: '' });
    onClean();
  }

  const handleSearch = (event) => {
    setState({ ...state, search: event.target.value });
  }

  const handleStatus = (event) => {
    setState({ ...state, status: event.target.value });
  }

  return <Stack>
    <Button
      color="inherit"
      startIcon={<FilterListIcon/>}
      sx={{ justifyContent: "flex-end" }}
      onClick={toggleVisible}
      data-testid={"open-filter"}
    >
      FILTRAR VISUALIZAÇÃO
    </Button>
    {
      state.visible &&
      <Card sx={{ mt: 1, p: 1 }}>
        <CardHeader
          title={"Defina os filtros"}
          titleTypographyProps={{ variant: "h5" }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={ smallScreen ? 12 : 6 }>
              <TextField
                fullWidth
                label={"Buscar por nome ou email do usuário"}
                placeholder="Buscar por nome ou email do usuário"
                value={state.search}
                onChange={handleSearch}
                data-testid={"user-filter-field"}
              />
            </Grid>
            <Grid item xs={ smallScreen ? 12 : 6 }>
              <FormControl fullWidth>
                <InputLabel id={"status-select-label"}>Selecionar status</InputLabel>
                <Select
                  fullWidth
                  label={"Selecionar status"}
                  value={state.status}
                  onChange={handleStatus}
                  labelId={"status-select-label"}
                  data-testid={"status-filter-select"}
                >
                  <MenuItem value={1}>Ativo</MenuItem>
                  <MenuItem value={0}>Expirado</MenuItem>
                  <MenuItem value={-1}>Cancelado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            color={"inherit"}
            data-testid={"clean-filter"}
            onClick={handleClean}
          >
            LIMPAR FILTROS
          </Button>
          <Button
            variant="contained"
            data-testid={"apply-filter"}
            onClick={() => onFilter(state.search, state.status)}
            disabled={ state.search === '' && state.status === '' }
          >
            FILTRAR
          </Button>
        </CardActions>
      </Card>
    }
  </Stack>
}

export default AdvanceScheduleFilter;