import cable from "../../cable";

const AccountBulkBalanceAdjustmentChannel = ({
  accountId,
  onReceive,
  onConnected,
}) => {
  return cable.subscriptions.create(
    { channel: "Account::BulkBalanceAdjustmentChannel", account_id: accountId },
    { received: (data) => onReceive(data), connected: () => onConnected() }
  );
};

export default AccountBulkBalanceAdjustmentChannel;
