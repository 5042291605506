import React, {useState} from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardMedia,
    IconButton,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from "@mui/material";
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';

const sortedSteps = files => {
    let steps = [...files];
    steps.sort((a, b) => a.order - b.order);
    return steps;
}

export const LightboxFormSteps = ({onNext, onBack, rootSelector, initialSteps}) => {
    const [steps, setSteps] = useState(sortedSteps(initialSteps));
    const [stepsError, setStepsError] = useState(null);

    const handleFiles = async event => {
        const files = event.target.files;
        const uris = await Promise.all(Array.from(files).map(file => readUri(file)));
        const steps = uris.map((uri, index) => ({file: files[index], image: uri, brightness: 'light'}));
        addFile(steps);
    };

    const readUri = file => new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });

    const addFile = (file) => {
        setStepsError(null);
        setSteps([...steps, ...file])
    }

    const validateFields = () => {
        if (steps.length === 0) {
            setStepsError('Deve selecionar pelo menos uma imagem')
            return false;
        }
        setStepsError(null);
        return true;
    }

    const handleStepMove = ({oldIndex, newIndex}) => {
        let item = steps[oldIndex];
        let deleted = [...steps.slice(0, oldIndex), ...steps.slice(oldIndex + 1)]
        setSteps([...deleted.slice(0, newIndex), item, ...deleted.slice(newIndex)])
    };

    const handleStepBrightness = (index) => (_, brightness) => {
        setSteps(Object.assign([], steps, {[index]: {...steps[index], brightness}}))
    }

    const handleStepDelete = (index) => () => {
        setSteps([...steps.slice(0, index), ...steps.slice(index + 1)])
    }

    const handleNext = () => {
        if (!validateFields()) return;
        onNext(steps);
    }

    const helperContainer = () => {
        return document.querySelector(rootSelector)?.shadowRoot ?? document;
    }

    return <>
        <Typography variant={'body2'} sx={{mt: 1}}>
            Selecione imagens com a proporção 16:10, com resolução de pelo menos 624 x 390.<br/>
        </Typography>
        <Typography variant={'body2'} sx={{mt: 1}}>
            Se a imagem for predominantemente escura, selecione o tema escuro <DarkModeIcon sx={{fontSize: 14}}/>,
            do contrário, deixe no tema claro <LightModeIcon sx={{fontSize: 14}}/>.
            Isso garantirá um contraste adequado entre os controles de navegação e imagem de fundo.<br/><br/>
        </Typography>
        <Typography variant={'body2'} sx={{mb: 3}}>
            Na última imagem serão posicionados os botões de ação, ligeiramente abaixo do centro da imagem.
            Verifique se não há nenhum componente visual sendo ocultado pelos botões na próxima etapa.
        </Typography>
        <StepList
            files={steps}
            filesError={stepsError}
            handleFile={handleFiles}
            handleBrightness={handleStepBrightness}
            onSortEnd={handleStepMove}
            axis={'xy'}
            useDragHandle
            helperContainer={helperContainer}
            handleDelete={handleStepDelete}/>
        {stepsError && <Typography variant={'caption'} color={'error.main'}>{stepsError}</Typography>}
        <Box sx={{my: 2}}>
            <div>
                <Button
                    data-testid={'lightbox-steps-continue-button'}
                    variant="contained"
                    onClick={handleNext}
                    sx={{mt: 1, mr: 1}}>
                    {'Continuar'}
                </Button>
                <Button
                    onClick={onBack}
                    sx={{mt: 1, mr: 1}}>
                    Voltar
                </Button>
            </div>
        </Box>
    </>
}

const StepList = SortableContainer(({files, handleBrightness, handleDelete, handleFile, filesError}) => {
    return <Stack direction={'row'} spacing={2} useFlexGap flexWrap="wrap">
        {files.map((item, index) => (
            <StepItem
                index={index}
                key={`image-${index}`}
                item={item}
                onChangeBrightness={handleBrightness(index)}
                onDelete={handleDelete(index)}/>
        ))}
        <Button
            color={filesError ? 'error' : 'primary'}
            component="label"
            variant="outlined"
            startIcon={<CloudUploadIcon/>}>
            Adicionar imagem
            <input data-testid={'input_file'} type="file" onChange={handleFile} hidden multiple accept=".jpg, .png, .jpeg"/>
        </Button>
    </Stack>
})

const StepImage = SortableHandle(({item}) => {
    return <Tooltip title='Segure e arraste para reordenar as imagens'>
        <CardMedia component='img' height={100} width={160} image={item.image ?? item.imageUrl}/>
    </Tooltip>
});

const StepItem = SortableElement(({item, onChangeBrightness, onDelete}) => {
    return <Card sx={{width: 160}}>
        <StepImage item={item}/>
        <CardActions disableSpacing>
            <Tooltip title='Escolha o tema da imagem'>
                <ToggleButtonGroup
                    value={item.brightness}
                    exclusive
                    onChange={onChangeBrightness}>
                    <ToggleButton value="light">
                        <LightModeIcon/>
                    </ToggleButton>
                    <ToggleButton value="dark">
                        <DarkModeIcon/>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Tooltip>
            <Tooltip title='Remover imagem'>
                <IconButton sx={{marginLeft: 'auto'}} onClick={onDelete} color={'error'}>
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>
        </CardActions>
    </Card>
})