import * as React from 'react';
import {espressoTheme} from "./theme";
import {EspressoScaffold} from "./EspressoTemplate";
import {hot} from "react-hot-loader";
import createCache from "@emotion/cache";
import {deepmerge} from "@mui/utils";
import {ThemeProvider} from "@mui/material";
import {CacheProvider} from "@emotion/react";
import I18n from "./I18n";

const ShadowTemplate = (props) => {
    return <ShadowTheme rootSelector={props.rootSelector}>
        <EspressoScaffold {...props}/>
    </ShadowTheme>
}

export const ShadowTheme = ({rootSelector, children}) => {
    const reactRoot = document.querySelector(rootSelector).shadowRoot
    const shadowRootElement = reactRoot.querySelector('#shadow_root')
    const emotionRoot = reactRoot.querySelector('#emotion_root')

    const cache = createCache({
        key: "css",
        prepend: true,
        container: emotionRoot
    });

    const shadowTheme = deepmerge(espressoTheme,
        {
            components: {
                MuiPopover: {
                    defaultProps: {
                        container: shadowRootElement
                    }
                },
                MuiPopper: {
                    defaultProps: {
                        container: shadowRootElement
                    }
                },
                MuiMenu: {
                    defaultProps: {
                        container: shadowRootElement
                    }
                },
                MuiModal: {
                    defaultProps: {
                        container: shadowRootElement
                    }
                }
            }
        }
    )


    return <ThemeProvider theme={shadowTheme}>
        <I18n>
            <CacheProvider value={cache}>
                {children}
            </CacheProvider>
        </I18n>
    </ThemeProvider>
}

export default hot(module)(ShadowTemplate)