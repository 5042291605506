import React from "react";
import {IntlProvider} from "react-intl";
import {defaultLocale, locales} from "../../i18n/i18n_config";

export default function I18n(props) {
    return (
        <IntlProvider
            locale={defaultLocale}
            defaultLocale={defaultLocale}
            messages={locales[defaultLocale].messages}>
            {props.children}
        </IntlProvider>
    );
}