import {Alert, AlertTitle} from "@mui/material";
import React from "react";

export const SankhyaProjectConfigSteps = () => {
  return <>
    <p><b>Passo 1: </b>Configuração no ERP</p>
    <ul>
      <li>
        <b>Acesse a tela "Dicionário de Dados"</b>
        para acessar vá até Configurações > Avançado > Dicionário de Dados.
      </li>
      <li>
        Em “Filtros rápidos” no campo de tabela digite TCSPRJ (tabela de Projeto) e
        clique em "Aplicar".
      </li>
    </ul>
    <p><b>Passo 2: </b>Campos personalizados</p>
    <ul>
      <li>Na Aba "Campos", clique em Novo [+].</li>
      <li>
        <b>Nome do campo:</b>
        pode ser um campo ou tag já existente, sugerimos que seja utilizado o nome
        “Espresso”.
      </li>
      <li>
        <b>Descrição do campo: </b>
        é o nome que vai identificar o campo durante o uso no sistema, sugerimos que
        seja utilizado “Integra no Espresso”.
      </li>
    </ul>
    <Alert severity="warning">
      <AlertTitle>Tipo de dados</AlertTitle>
      Atenção, é necessário definir o campo “Tipo de dados” como “Conteúdo
      binário”.
    </Alert>
    <ul>
      <li>
        <b>Apresentação</b> o campo apresentação defina como “Checkbox”.
      </li>
      <li>
        <b>Permite pesquisa?</b>
        marque essa opção para permitir a pesquisa dos centros de resultados pelo Espresso.
      </li>
      <li>
        <b>Visível no grid de pesquisa?</b>
        marque essa opção para exibir o campo no grid de busca.
      </li>
    </ul>
    <p><b>Passo 3: </b>Salvar os campos personalizados</p>
    <ul>
      <li>Clique em “✔” para salvar;</li>
    </ul>
    <Alert severity="info">
      A partir dessa configuração será possível configurar os projetos que devem
      ser integrados, marcando o checkbox do novo campo criado.
    </Alert>
  </>
}
