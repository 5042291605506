import * as React from 'react';
import EspressoDrawer from "./navigation_drawer/EspressoDrawer";
import EspressoAppbar from "./appbar/EspressoAppbar";
import {AppbarOffset} from "./navigation_drawer/NavigationDrawer";
import EspressoMain from "./EspressoMain";
import {espressoTheme} from "./theme";
import {hot} from "react-hot-loader";
import {CssBaseline, ThemeProvider, useMediaQuery} from "@mui/material";
import I18n from "./I18n";

const EspressoTemplate = ({copilotMode, appbarProps, drawerProps, children}) => {
    return <EspressoTheme>
        <EspressoScaffold
            copilotMode={copilotMode}
            appbarProps={appbarProps}
            drawerProps={drawerProps}>
            {children}
        </EspressoScaffold>
    </EspressoTheme>
}

export const EspressoTheme = ({children}) => {
    return <ThemeProvider theme={espressoTheme}>
        <I18n>
            <CssBaseline/>
            {children}
        </I18n>
    </ThemeProvider>
}

export const EspressoScaffold = ({copilotMode, appbarProps, drawerProps, children}) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [sidebarOpen, setSidebarOpen] = React.useState(true);

    const setOpen = (open) => {
        handleMainSection(open)
        setSidebarOpen(open)
    }

    const handleMainSection = (open) => {
        let element = document.querySelector("main");
        if (element) {
            if (open) {
                element.classList.remove("sidebar-closed");
            } else {
                element.classList.add("sidebar-closed");
            }
        }
    }

    const handleMenu = () => setOpen(!sidebarOpen)

    const handleCloseSidebar = () => setOpen(false)

    React.useEffect(() => setOpen(!isMobile), [isMobile])

    return <>
        <EspressoAppbar
            copilotMode={copilotMode}
            onClickMenu={handleMenu}
            {...appbarProps}/>
        <EspressoDrawer
            copilotMode={copilotMode}
            open={sidebarOpen}
            onClose={handleCloseSidebar}
            {...drawerProps}/>
        {children && (
            <EspressoMain drawerOpen={!isMobile && sidebarOpen}>
                <AppbarOffset/>
                {children}
            </EspressoMain>
        )}
    </>
}

export default hot(module)(EspressoTemplate)