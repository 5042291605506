import {
    admin_classifications_category_restrictions_path,
    admin_classifications_classification_restrictions_path,
    admin_classifications_restrictions_category_subcategories_path,
    admin_classifications_restrictions_cost_centers_path,
    admin_classifications_restrictions_custom_fields_path,
    admin_classifications_restrictions_tags_groups_path,
} from "../../../routes";
import * as request from "@rails/request.js";

const fetchClassifications = async ({page, search, url}) => {
    const params = {page: page, filter: {search: search}};
    const response = await request.get(url(params), {responseKind: 'json'});
    if (response.ok) {
        return response.json;
    }
    return null;
}

export const fetchCostCenters = (page, search) => {
    return fetchClassifications({
        page: page,
        search: search,
        url: admin_classifications_restrictions_cost_centers_path,
    });
}

export const fetchTagsGroups = (page, search) => {
    return fetchClassifications({
        page: page,
        search: search,
        url: admin_classifications_restrictions_tags_groups_path,
    });
}

export const fetchCustomFields = (page, search) => {
    return fetchClassifications({
        page: page,
        search: search,
        url: admin_classifications_restrictions_custom_fields_path,
    });
}

export const fetchSubcategories = (categoryId, page, search) => {
    return fetchClassifications({
        page: page,
        search: search,
        url: (params) => admin_classifications_restrictions_category_subcategories_path(categoryId, params),
    });
}

export const restrictCategory = async (categoryId, subcategoryId, type, allExcept, classificationIds) => {
    const url = admin_classifications_category_restrictions_path(categoryId);
    const response = await request.post(url, {
        body: JSON.stringify({
            type: type,
            all_except: allExcept,
            subcategory_id: subcategoryId,
            classification_ids: classificationIds
        })
    });
    return response.ok;
}

export const restrictClassification = async (type, classificationId, categoryId, subcategoryIds, allExcept) => {
    const url = admin_classifications_classification_restrictions_path(type, classificationId);
    const response = await request.post(url, {
        body: JSON.stringify({
            category_id: categoryId,
            subcategory_ids: subcategoryIds,
            all_except: allExcept
        })
    });
    return response.ok;
}
