import {Grid, Checkbox, Typography} from "@mui/material";
import React from "react";

export const OmieCostCenterConfigForm = ({restricted, onChangeRestricted, disabled, failed}) => {
    return <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} md={12} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Checkbox
                checked={restricted}
                label={'Restricted'}
                disabled={disabled}
                inputProps={{'data-testid':'checkbox-restricted'}}
                onChange={(e) => onChangeRestricted(e.target.checked)}
            />
            <Typography>
                Deseja que os centros de custos importados sejam privados?
            </Typography>
        </Grid>
    </Grid>
}