import {Grid, InputLabel, MenuItem, Select, Typography, Checkbox} from "@mui/material";
import React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

export const SankhyaCostCenterConfigForm = ({data, onChangeData, disabled, failed}) => {
    return <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12}>
            <Typography variant={'h6'}>
                Informe os campos criados na configuração do ERP
            </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
            <TextField
                fullWidth
                required
                error={failed}
                label={'Campo'}
                value={data.field}
                disabled={disabled}
                placeholder={'AD_ESPRESSO'}
                onChange={(e) => onChangeData({...data, field: e.target.value})}
                helperText={failed ? 'Verifique os dados e tente novamente' : ''}/>
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField
                fullWidth
                required
                error={failed}
                label={'Valor'}
                value={data.value}
                disabled={disabled}
                placeholder={'S'}
                onChange={(e) => onChangeData({...data, value: e.target.value})}
                helperText={failed ? 'Verifique os dados e tente novamente' : ''}/>
        </Grid>
        <Grid item xs={12} md={12}>
            <FormControl fullWidth>
                <InputLabel>Rotina</InputLabel>
                <Select
                    label={'Rotina'}
                    value={data.frequency}
                    defaultValue={'unset'}
                    onChange={(e) => onChangeData({...data, frequency: e.target.value})}>
                    <MenuItem value={'daily'}>Diária</MenuItem>
                    <MenuItem value={'weekly'}>Semanal</MenuItem>
                    <MenuItem value={'monthly'}>Mensal</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={12} md={12} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Checkbox
                checked={data.restricted}
                label={'Priv'}
                disabled={disabled}
                inputProps={{'data-testid':'checkbox-restricted'}}
                onChange={(e) => onChangeData({...data, restricted: e.target.checked})}
            />
            <Typography>
                Deseja que os centros de custos importados sejam privados?
            </Typography>
        </Grid>
    </Grid>
}