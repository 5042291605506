import React, {useCallback, useRef, useState} from "react";

import {Stack, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {hot} from "react-hot-loader";
import EspressoTemplate from "../../../EspressoTemplate";
import {PageNavbar} from "../../../components/PageNavbar";
import {ErpTemplateResume} from "./components/ErpTemplateResume";
import {
    admin_erp_integration_template_activity_logs_path,
    admin_erp_integration_template_path,
    admin_erp_integration_templates_path
} from "../../../../../routes";
import {EventLogDialog} from "./components/EventLogDialog";
import {RelatedEventsDialog} from "./components/RelatedEventsDialog";
import {EventItem} from "./components/EventItem";
import {activateTemplate, deactivateTemplate,} from "../erp_template_connector";
import {AsyncConfirmationDialog} from "../../../../components/dialog/AsyncConfirmationDialog";
import {PaginatedList} from "./components/PaginetedList";
import {archiveEvent, fetchTemplateEvents} from "./erp_template_event_connector";

const Index = (props) => {
    const {
        erp,
        integration,
        templateType,
        template,
        failureCount = 0,
        eventsCount = 0,
        failures = [],
        events = []
    } = props

    const [deactivating, setDeactivating] = useState(false)
    const [activating, setActivating] = useState(false)

    const fetchEvents = useCallback(async (page) => {
        return fetchTemplateEvents(erp, template.templateType, page, 20, false)
    }, []);

    const fetchFailures = useCallback(async (page) => {
        return fetchTemplateEvents(erp, template.templateType, page, 5, true)
    }, []);

    const deactivate = useCallback(async () => {
        const success = await deactivateTemplate(erp, template.templateType)
        if (!success) {
            return 'Não foi possível desativar o template'
        }
    }, [template]);

    const activate = useCallback(async () => {
        const success = await activateTemplate(erp, template.templateType)
        if (!success) {
            return 'Não foi possível reativar o template'
        }
    }, [template]);

    const definePageTitle = (templateType) => {
        switch (templateType) {
            case 'user':
                return 'Usuários';
            case 'cost_center':
                return 'Centro de Custos';
            case 'project':
                return 'Projetos';
        }
    }

    const [logEvent, setLogEvent] = useState(null)
    const [relatedEvent, setRelatedEvent] = useState(null)
    const closeLog = () => setLogEvent(null);
    const closeRelated = () => setRelatedEvent(null);

    return <EspressoTemplate {...props}>
        <Stack>
            <PageNavbar
                title={definePageTitle(templateType)}
                backTo={admin_erp_integration_templates_path(erp)}
                options={template && [
                    {
                        label: 'Desativar template',
                        onClick: () => setDeactivating(true),
                        disabled: integration.status !== 'connected' || template?.active === false,
                    },
                    {
                        label: 'Editar configuração',
                        href: admin_erp_integration_template_path(erp, templateType),
                        disabled: integration.status !== 'connected' || template?.active === false,
                    },
                    {
                        label: 'Reativar template',
                        onClick: () => setActivating(true),
                        disabled: integration.status !== 'connected' || template?.active,
                    },
                    {
                        label: 'Histórico de ações',
                        href: admin_erp_integration_template_activity_logs_path(erp, templateType),
                    }
                ]}/>
            <Container maxWidth={'lg'} sx={{my: 5}}>
                <ErpTemplateResume
                    erp={erp}
                    template={template}
                    integration={integration}
                    templateType={templateType}/>
                {failureCount > 0 && (
                    <EventsSection
                        perPage={5}
                        events={failures}
                        title={'Erros encontrados'}
                        eventsCount={failureCount}
                        fetchEvents={fetchFailures}
                        onOpenLog={setLogEvent}
                        onArchive={(uuid) => archiveEvent(erp, templateType, uuid)}
                        onOpenRelated={setRelatedEvent}
                        templateType={templateType}/>
                )}
                {eventsCount > 0 && (
                    <EventsSection
                        perPage={20}
                        events={events}
                        title={'Integrações'}
                        eventsCount={eventsCount}
                        fetchEvents={fetchEvents}
                        onOpenLog={setLogEvent}
                        onOpenRelated={setRelatedEvent}
                        templateType={templateType}/>
                )}
                {logEvent && (
                    <EventLogDialog event={logEvent} onClose={closeLog}/>
                )}
                {relatedEvent && (
                    <RelatedEventsDialog
                        erp={erp}
                        event={relatedEvent}
                        onClose={closeRelated}
                        templateType={template.templateType}/>
                )}
            </Container>
            <AsyncConfirmationDialog
                open={activating}
                onClose={() => setActivating(false)}
                afterConfirm={() => window.location.reload()}
                confirmAction={activate}
                confirmLabel={'Reativar'}
                disableWith={'Reativando...'}
                confirmWith={'Reativado'}
                title={'Reativar template?'}
                content={'Tem certeza de que deseja reativar este template?'}/>
            <AsyncConfirmationDialog
                destructive={true}
                open={deactivating}
                onClose={() => setDeactivating(false)}
                afterConfirm={() => window.location.reload()}
                confirmAction={deactivate}
                confirmLabel={'Desativar'}
                disableWith={'Desativando...'}
                confirmWith={'Desativado'}
                title={'Tem certeza de que deseja desativar este template?'}
                content={'O template será desativado e suas funções serão interrompidas.'}/>
        </Stack>
    </EspressoTemplate>
}

const EventsSection = (props) => {
    const {
        title,
        perPage,
        events,
        eventsCount,
        fetchEvents,
        onOpenLog,
        onOpenRelated,
        onArchive,
        templateType
    } = props;
    const pageRef = useRef(null);

    const [empty, setEmpty] = useState(false)

    return !empty && <>
        <Typography sx={{my: 3}} variant={'h5'}>{title}</Typography>
        <PaginatedList
            ref={pageRef}
            items={events}
            perPage={perPage}
            fetchPage={fetchEvents}
            totalCount={eventsCount}
            onEmpty={() => setEmpty(true)}
            itemBuilder={(event) => (
                <EventItem
                    event={event}
                    key={event.uuid}
                    onArchive={onArchive}
                    onOpenLog={() => onOpenLog(event)}
                    afterArchive={() => pageRef.current.refresh()}
                    onOpenRelated={() => onOpenRelated(event)}
                    templateType={templateType}/>
            )}/>
    </>
}

export default hot(module)(Index)