import React, { useEffect, useState } from "react";
import { fetchAdvanceSchedulesBy } from "./advance_schedules_service";
import { Card, CardContent, LinearProgress, Pagination, Stack, Typography } from "@mui/material";
import AdvanceScheduleListItem from "./AdvanceScheduleListItem";

const AdvanceScheduleExpiredCard = ({ smallScreen, refreshs, onRefresh }) => {
  const [state, setState] = useState({
    loading: false,
    error: false,
    pages: 0,
    data: []
  });

  const [page, setPage] = useState(1);
  
  const handlePage = (_, value) => {
    setPage(value);
  }

  const fetchAdvanceSchedules = async () => {
    setState({ ...state, loading: true, error: false });
    const body = await fetchAdvanceSchedulesBy(page, '', 0, 5);
    setState({
      ...state,
      loading: false,
      error: body === null,
      pages: body?.pages ?? 0,
      data: body?.data ?? []
    });
  }

  useEffect(() => {
    fetchAdvanceSchedules();
  }, [page, refreshs]);

  const empty = state.data.length === 0 && page === 1 && !state.loading && !state.error;

  if (empty) return null;

  return <Stack
    mt={2}
    spacing={2}
    data-testid={"expired-card"}
  >
    <Typography variant="h5">
      Expirados
    </Typography>
    <Card>
      <CardContent sx={{ px: 0, pt: 0 }}>
        {
          state.loading &&
          <LinearProgress sx={{ mx: 2, mt: 3 }}/>
        }
        {
          state.error &&
          <Typography
            color={"error"}
            sx={{ mt: 2, mx: 2 }}
          >
            Erro ao carregar recorrências
          </Typography>
        }
        {
          state.data.map((schedule, index) => (
            <AdvanceScheduleListItem
              key={index}
              schedule={schedule}
              smallScreen={smallScreen}
              onRefresh={onRefresh}
            />
          ))
        }
        {
          state.pages > 0 &&
          <Stack
            alignItems={"end"}
            sx={{ mt: 2 }}
          >
            <Pagination
              shape="rounded"
              color="primary"
              count={state.pages}
              page={page}
              onChange={handlePage}
              data-testid={"pagination"}
            />
          </Stack>
        }
      </CardContent>
    </Card>
  </Stack>
}

export default AdvanceScheduleExpiredCard;