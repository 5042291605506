import dayjs from "dayjs";
import { admin_advance_schedule_path, admin_advance_schedules_path, admin_advance_schedules_tags_group_tags_path, admin_advance_schedules_user_tags_groups_path, admin_advance_schedules_users_path, cost_centers_admin_advance_schedules_user_path, custom_fields_admin_advance_schedules_user_path, admin_advance_schedule_activity_logs_path } from "../../../routes";
import { parseAmount } from "../../shared/balance_service";
import * as request from "@rails/request.js";

export const creationDateBy = (day) => {
  if (!day) return null;
  const today = dayjs().startOf('day');
  if (day > today.date()) {
    return today.date(day);
  }
  return today.add(1, 'month').date(day);
}

export const validateExpirationBy = (permanent, expiresAt, creationDate, editing, initialExpiresAt) => {
  if (permanent) {
    return true;
  } else if (!expiresAt.isValid()) {
    return false;
  } else if (editing?.expired ?? false) {
    return !expiresAt.isBefore(creationDate);
  } else if (editing) {
    const creationDateDay = creationDate.date().toString();
    const initialCreationDay = editing.creationDay?.toString() ?? '';
    const isSameDates = creationDateDay === initialCreationDay && expiresAt.isSame(initialExpiresAt);
    return isSameDates || !expiresAt.isBefore(creationDate);
  }
  return !expiresAt.isBefore(creationDate);
}

export const expiresAtErrorMessage = (expiresAt, minExpirationDate, expired) => {
  if (expiresAt.isValid() && (expired || expiresAt.isBefore(minExpirationDate))) {
    return 'Deve ser maior ou igual à data de registro';
  }
  return 'Data de encerramento ausente ou inválida';
}

export const fetchTagsBy = async (tagsGroupId, page, search) => {
  const params = { page: page, filter: { search: search } };
  const url = admin_advance_schedules_tags_group_tags_path(tagsGroupId, params)
  const response = await request.get(url, { responseKind: 'json' });
  if (response.ok) {
    return response.json;
  }
  return null;
}

export const fetchTagsGroupsBy = async (companyUserId) => {
  const url = admin_advance_schedules_user_tags_groups_path(companyUserId);
  const response = await request.get(url, { responseKind: 'json' });
  if (response.ok) {
    return response.json;
  }
  return null;
}

export const fetchCustomFieldsBy = async (companyUserId) => {
  const url = custom_fields_admin_advance_schedules_user_path(companyUserId);
  const response = await request.get(url, { responseKind: 'json' });
  if (response.ok) {
    return response.json;
  }
  return null;
}

export const fetchCostCentersBy = async (companyUserId, page, search) => {
  const params = { page: page, filter: { search: search } };
  const url = cost_centers_admin_advance_schedules_user_path(companyUserId, params);
  const response = await request.get(url, { responseKind: 'json' });
  if (response.ok) {
    return response.json;
  }
  return null;
}

export const fetchUsersBy = async (page, search) => {
  const params = { page: page, filter: { search: search } };
  const url = admin_advance_schedules_users_path(params);
  const response = await request.get(url, { responseKind: 'json' });
  if (response.ok) {
    return response.json;
  }
  return null;
}

export const createAdvanceSchedule = async (dto, onSuccess, onFail) => {
  const response = await request.post(admin_advance_schedules_path(), {
    body: JSON.stringify(dto)
  });
  if (response.ok) {
    onSuccess();
    window.location.assign(admin_advance_schedules_path());
  } else {
    onFail();
    window.alert('Não foi possível criar o fundo fixo');
  }
}

export const updateAdvanceSchedule = async (id, dto, onSuccess, onFail) => {
  const response = await request.put(admin_advance_schedule_path(id), {
    body: JSON.stringify(dto)
  });
  if (response.ok) {
    onSuccess();
    window.location.assign(admin_advance_schedules_path());
  } else {
    onFail();
    window.alert('Não foi possível atualizar o fundo fixo');
  }
}

export const statusCardTitle = (status) => {
  if (status === 0) return 'Expirados';
  return 'Recorrências';
}

export const fetchAdvanceSchedulesBy = async (page, search, status, count) => {
  const params = { page: page, filter: { search: search, status: status, count: count } };
  const url = admin_advance_schedules_path(params);
  const response = await request.get(url, { responseKind: 'json' });
  if (response.ok) {
    return response.json;
  }
  return null;
}

export const cancelAdvanceSchedule = async (id, onSuccess) => {
  const response = await request.destroy(admin_advance_schedule_path(id));
  if (response.ok) {
    onSuccess();
  } else {
    window.alert('Não foi possível cancelar o fundo fixo');
  }
}

export const fetchAdvanceScheduleLogsBy = async (id, page) => {
  const url = admin_advance_schedule_activity_logs_path(id, { page: page });
  const response = await request.get(url, { responseKind: 'json' });
  if (response.ok) {
    return response.json;
  }
  return null;
}

export class AdvanceScheduleDto {
  constructor({
    companyUserId, creationDay, usageDay, permanent, expiresAt, currency, cost,
    reason, tags, costCenters, customFields
  }) {
    this.advance_schedule = {
      company_user_id: companyUserId,
      creation_day: creationDay,
      usage_day: usageDay,
      expires_at: permanent ? null : expiresAt,
      currency: currency,
      cost: parseAmount(cost),
      reason: reason,
      tag_ids: Object.values(tags),
      cost_center_ids: costCenters,
      custom_fields: Object.entries(customFields).map(([id, value]) => ({ id: id, value: value }))
    }
  }
}