import React from "react";

import {
  parserCNPJ,
  parserCPF,
  parserDate,
  parserHolderType,
  parserMainActivity,
  parserPhone,
  parser_resume_address,
} from "../../../shared/helpers";

export function parserSwapAccountObjectList(swap_account) {
  let section_list = [
    [
      { label: "Empresa", description: swap_account.company_name },
      {
        label: "Data de solicitação de abertura",
        description: parserDate(swap_account.created_at),
      },
      {
        label: "Data da última atualização",
        description: parserDate(swap_account.updated_at),
      },
      {
        label: "Treasury_account_id",
        description: swap_account.treasury_account_id,
      },
      {
        label: "Treasury_account_holder_id",
        description: swap_account.treasury_account_holder_id,
      },
      {
        label: "Treasury_account_status",
        description: swap_account.treasury_account_status,
      },
      {
        label: "Account_holder_id",
        description: swap_account.account_holder_id,
      },
      { label: "Status", description: swap_account.kyc_status },
      {
        label: "Análise de resultados",
        description: swap_account.kyc_analysis_results,
      },
    ],
  ];

  if (swap_account.legal_infos) {
    let legal_infos = [];

    if (swap_account.legal_infos.business) {
      legal_infos = [
        {
          label: "CNPJ",
          description: parserCNPJ(swap_account.legal_infos.cnpj),
        },
        {
          label: "Razão Social",
          description: swap_account.legal_infos.legal_name,
        },
        {
          label: "Data de fundação",
          description: parserDate(swap_account.legal_infos.founding_date),
        },
        {
          label: "Principal atividade",
          description: parserMainActivity(
            swap_account.legal_infos.main_activity
          ),
        },
        {
          label: "Tipo de empresa",
          description: swap_account.legal_infos.business_type,
        },
      ];
    } else {
      legal_infos = [
        {
          label: "Inscrição Estadual",
          description: swap_account.legal_infos.state_registration,
        },
      ];
    }

    section_list.push([
      ...legal_infos,
      { label: "E-mail", description: swap_account.legal_infos.email },
      {
        label: "Telefone",
        description: parserPhone(swap_account.legal_infos.phone),
      },
      {
        label: "Endereço",
        description: parser_resume_address(
          swap_account.legal_infos.address.street,
          swap_account.legal_infos.address.number,
          swap_account.legal_infos.address.complement,
          swap_account.legal_infos.address.postal_code,
          swap_account.legal_infos.address.neighborhood,
          swap_account.legal_infos.address.city,
          swap_account.legal_infos.address.state
        ),
      },
    ]);
  }

  const holders = swap_account.holders.filter(
    (holder) => holder.holder_type === "physical"
  );

  if (holders.length > 0) {
    holders.forEach((holder, idx) => {
      holder = [
        { label: "CPF", description: parserCPF(holder.document) },
        { label: "Nome", description: holder.name },
        {
          label: "Data de Nascimento",
          description: parserDate(holder.birth_date),
        },
        { label: "E-mail", description: holder.email },
        {
          label: "Telefone",
          description: holder.phone && parserPhone(holder.phone),
        },
        {
          label: "Account_holder_id",
          description: holder.account_holder_id,
        },
        { label: "Status", description: holder.kyc_status },
        {
          label: "Análise de resultados",
          description: holder.kyc_analysis_results,
        },
        {
          label: "Sócio principal",
          description: renderTrueOrFalseModalDetail(
            holder.principal,
            "Sim",
            "Não"
          ),
        },
      ];

      if (swap_account?.legal_infos?.business) {
        holder.push({
          label: "Tipo de sócio",
          description: parserHolderType(holder.partner_type),
        });
      }

      section_list.push(holder);
    });
  }

  return section_list;
}

export function parserSectionTittles(swap_account) {
  let section_tittles = ["Conta"];

  if (swap_account.legal_infos) {
    section_tittles.push("Dados da empresa");
  }

  const holders = swap_account.holders.filter(
    (holder) => holder.holder_type === "physical"
  );

  if (holders.length > 0) {
    holders.forEach((holder, idx) => {
      section_tittles.push(
        `Sócio${swap_account?.legal_infos?.business ? ` PF (${idx + 1})` : ""}`
      );
    });
  }

  return section_tittles;
}

export function renderTrueOrFalseModalDetail(value, valueTrue, valueFalse) {
  if (value) {
    return (
      <React.Fragment>
        <i className="mdi mdi-check-circle icon icon-true" />
        {valueTrue}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <i className="mdi mdi-close-circle icon icon-false" />
        {valueFalse}
      </React.Fragment>
    );
  }
}
