import React from "react";
import { Alert, AlertTitle, Box, Button, Card, CardActions, CardContent, CardHeader, Divider, List, ListItem, Typography } from "@mui/material";
import AdvanceScheduleImage from '../../../../../app/assets/images/advance_schedule.svg';
import { new_admin_advance_schedule_path } from "../../../routes";

const AdvanceScheduleEmptyPage = ({ theme, smallScreen }) => {
  return <Card
    data-testid={"advance-schedule-empty-page"}
    sx={{ mx: smallScreen ? "1rem" : "10rem", p: 1, mb: 10, mt: 5 }}
  >
    <CardHeader
      title="Configure adiantamentos em espécie recorrentes"
      titleTypographyProps={{ variant: "h6" }}
    />
    <CardContent sx={{ pt: 0 }}>
      <Box
        py={3}
        mb={2}
        display={"flex"}
        justifyContent={"center"}
        sx={{ backgroundColor: theme.palette.surfaceLight9.main }}
      >
        <AdvanceScheduleImage/>
      </Box>
      <Typography color={"GrayText"}>
        Imagine uma gestão de adiantamentos de saldo em espécie onde tudo flui automaticamente. Com o fundo fixo, você deixa as tarefas repetitivas no passado e foca no que realmente importa.
      </Typography>
      <Alert severity="info" sx={{ mt: 2 }}>
        <AlertTitle sx={{ mb: 0 }} >Importante:</AlertTitle>
        O adiantamento registrado no fundo fixo não será submetido ao fluxo de aprovação e já será criado como aprovado.
      </Alert>
      <Divider sx={{ my: 2 }}/>
      <Typography fontWeight={"bold"} mb={2}>
        O que é?
      </Typography>
      <Typography color={"GrayText"}>
        Uma forma de agendar registros de adiantamentos em espécie de forma recorrente (automática), evitando processos manuais.
      </Typography>
      <Typography color={"GrayText"}>
        Os registros de adiantamento criados a partir da configuração terão o status "Aguardando pagamento".
      </Typography>
      <Typography fontWeight={"bold"} mt={2} mb={1}>
        Por que fazer?
      </Typography>
      <List sx={{ listStyle: "disc", mx: 2 }}>
        <ListItem disablePadding sx={{ display: "list-item", color: "GrayText" }}>
          Economize tempo e reduza o risco de erros.
        </ListItem>
        <ListItem disablePadding sx={{ display: "list-item", color: "GrayText" }}>
          Mantenha previsibilidade financeira com pagamentos recorrentes, conforme a necessidade.
        </ListItem>
      </List>
      <Typography fontWeight={"bold"} my={1}>
        Como configurar a recorrência:
      </Typography>
      <List sx={{ listStyle: "decimal", mx: 2 }}>
        <ListItem disablePadding sx={{ display: "list-item", color: "GrayText" }}>
          Associe o usuário.
        </ListItem>
        <ListItem disablePadding sx={{ display: "list-item", color: "GrayText" }}>
          Defina a recorrência de registro do adiantamento, indique se haverá uma data de expiração ou se será contínuo.
        </ListItem>
        <ListItem disablePadding sx={{ display: "list-item", color: "GrayText" }}>
          Insira o valor.
        </ListItem>
        <ListItem disablePadding sx={{ display: "list-item", color: "GrayText" }}>
          Adicione a classificação do registro de acordo com as permissões.
        </ListItem>
        <ListItem disablePadding sx={{ display: "list-item", color: "GrayText" }}>
          Descreva o motivo do adiantamento, explicando brevemente sua finalidade.
        </ListItem>
        <ListItem disablePadding sx={{ display: "list-item", color: "GrayText" }}>
          Clique em “Salvar”.
        </ListItem>
      </List>
      <Typography color={"GrayText"} mt={1}>
        Pronto! A recorrência estará configurada e ativa. 😊
      </Typography>
    </CardContent>
    <CardActions sx={{ justifyContent: "flex-end" }}>
      <Button
        variant="contained"
        href={new_admin_advance_schedule_path()}
        data-testid={"new-advance-schedule"}
      >
        REGISTRAR FUNDO FIXO
      </Button>
    </CardActions>
  </Card>
}

export default AdvanceScheduleEmptyPage;