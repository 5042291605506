import {get} from "@rails/request.js";
import {bistro_company_user_access_logs_path} from "../../../../../../routes";

export const fetchAccessLogs = async (companyId, userId, page) => {
    const response = await get(
        bistro_company_user_access_logs_path(companyId, userId, {page}),
        {responseKind: 'json'}
    )
    if (response.ok) {
        return response.json
    }
    return {page, perPage: 20, data: []}
}

export const downloadAccessLogs = async (companyId, userId, {format}) => {
    const result = await get(
        bistro_company_user_access_logs_path(companyId, userId, {format}),
    )
    if (result.ok) {
        return result.response.blob();
    }
    return null;
}