import React, {useCallback, useRef} from "react";
import {ShadowTheme} from "../../../../../admin/ShadowTemplate";
import {
    BistroTableAction,
    BistroTableCell,
    BistroTableColumn,
    BistroTableRow,
    EspressoTable
} from "../../../../../components/collection/table/EspressoTable";
import {downloadAccessLogs, fetchAccessLogs} from "./access_logs_service";
import {hot} from "react-hot-loader";
import {Link} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import {downloadFile} from "../../../../../shared/helpers";

function AccessLogsCardShadow(props) {
    return (
        <ShadowTheme rootSelector={props.rootSelector}>
            <AccessLogsCard {...props}/>
        </ShadowTheme>
    )
}

function AccessLogsCard(props) {
    const {companyId, userId, data, totalCount} = props;

    const fetchPage = useCallback(async (page) => {
        const body = await fetchAccessLogs(companyId, userId, page)
        return {...body, data: accessLogsRows(body.data)}
    }, [companyId, userId]);

    const downloadCsv = async () => {
        const blob = await downloadAccessLogs(companyId, userId, {format: 'csv'});
        await downloadFile(blob, `access_logs_${userId}.csv`);
    }

    return <EspressoTable
        data={accessLogsRows(data)}
        totalCount={totalCount}
        title={'Histórico de acesso'}
        hoverRows={false}
        selectable={false}
        fetchPage={fetchPage}
        columns={accessLogsColumns}
        fixedPageSize={true}
        emptyMessage={'Nenhum acesso registrado até o momento'}
        actions={[
            BistroTableAction({
                id: 'export-csv',
                width: 224,
                icon: <DownloadIcon/>,
                tooltip: 'Exporta logs de acesso para CSV',
                label: 'Exportar CSV',
                variant: 'outlined',
                onClick: downloadCsv,
            })
        ]}/>
}

const accessLogsColumns = [
    BistroTableColumn({id: 'event', label: 'Evento', align: 'left', padding: 'normal',}),
    BistroTableColumn({id: 'platform', label: 'Plataforma', align: 'left'}),
    BistroTableColumn({id: 'ip_address', label: 'Endereço de IP', align: 'left'}),
    BistroTableColumn({id: 'performed_at', label: 'Data e hora', align: 'left'}),
    BistroTableColumn({
        id: 'localization',
        label: 'Localização',
        align: 'left',
        cellBuilder: (cell, log) => {
            if (log.localizationUrl) {
                return <Link href={log.localizationUrl} target="_blank">{cell.value}</Link>
            }
            return cell.value;
        }
    }),
];

const accessLogsRows = (logs) => {
    return logs.map((log) => BistroTableRow({
        id: log.id,
        data: log,
        cells: new Map([
            ['event', BistroTableCell({value: log.event})],
            ['platform', BistroTableCell({value: log.platform})],
            ['ip_address', BistroTableCell({value: log.ipAddress})],
            ['localization', BistroTableCell({value: log.localization})],
            ['performed_at', BistroTableCell({value: log.performedAt})],
        ])
    }));
}

export default hot(module)(AccessLogsCardShadow)