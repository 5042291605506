import { Button, CardHeader, Dialog, DialogActions, DialogContent, List, ListItem } from "@mui/material";
import React from "react";

const AdvanceScheduleDetailsModal = ({ open, onClose, details }) => {
  return <Dialog
    open={open}
    maxWidth={"md"}
    fullWidth={true}
    disableAutoFocus={true}
    data-testid={"defails-modal"}
  >
    <CardHeader
      title={"Detalhes"}
      titleTypographyProps={{ variant: "h6" }}
      sx={{ pb: 0, mx: 1 }}
    />
    <DialogContent sx={{ mx: 3, py: 1 }}>
      <List sx={{ listStyle: "disc" }}>
        {
          details.map((detail, index) => {
            return <ListItem
              key={index}
              sx={{ display: "list-item", color: "GrayText" }}
              disablePadding
            >
              {detail}
            </ListItem>
          })
        }
      </List>
    </DialogContent>
    <DialogActions>
      <Button
        color={"inherit"}
        onClick={onClose}
        data-testid={"close-details-modal"}
      >
        FECHAR
      </Button>
    </DialogActions>
  </Dialog>
}

export default AdvanceScheduleDetailsModal;