import { Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { grayFocusedStyles } from "./AdvanceScheduleCostAllocationField";

const AdvanceScheduleReasonField = ({ validating, onChangeReason, initialValue }) => {
  const [state, setState] = useState({ reason: initialValue ?? '' });

  const handleReason = (event) => {
    const reason = event.target.value;
    setState({ ...state, reason: reason });
    onChangeReason(reason);
  }

  const error = validating && state.reason === '';

  return <Grid container mt={2}>
    <Grid item xs={12}>
      <Typography variant="h6">
        5. Motivo do adiantamento
      </Typography>
    </Grid>
    <Grid item xs={12} mt={2}>
      <TextField
        fullWidth
        label="Motivo"
        variant="outlined"
        rows={4}
        placeholder="Escreva aqui uma breve descrição sobre a recorrência (Ex.: Fundo fixo para viagens)"
        value={state.reason}
        onChange={handleReason}
        error={error}
        helperText={error && "Esse campo é obrigatório"}
        InputProps={{ sx: { pb: "50px" } }}
        data-testid={"reason-field"}
        focused={true}
        sx={grayFocusedStyles}
      />
    </Grid>
  </Grid>
}

export default AdvanceScheduleReasonField;