import React from 'react';
import {ShadowTheme} from "../../../ShadowTemplate";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SingleItemSetting from "../../components/SingleItemSetting";
import {admin_classifications_category_restrictions_path,} from "../../../../../routes";
import {hot} from "react-hot-loader";
import {FormattedMessage as Intl} from "react-intl";

function CategoryRestrictionSectionShadow(props) {
    return <ShadowTheme rootSelector={props.rootSelector}>
        <CategoryRestrictionSection {...props} />
    </ShadowTheme>
}

function CategoryRestrictionSection({categoryId, subcategoryId, restrictive}) {
    const level = subcategoryId ? 'subcategory' : 'category';
    const open = restrictive ? 'restrictive' : 'open';

    return <SingleItemSetting
        title={<Intl id={'restriction.category_section.title'}/>}
        subtitle={<Intl id={`restriction.${level}_section.subtitle`}/>}
        itemTitle={<Intl id={`restriction.${level}_section.item_title.${open}`}/>}
        itemSubtitle={<Intl id={`restriction.${level}_section.item_subtitle.${open}`}/>}
        actionLabel={<Intl id={'restriction.category_section.action'}/>}
        actionHref={admin_classifications_category_restrictions_path(categoryId, {subcategory_id: subcategoryId})}
        icon={<AppRegistrationIcon/>}
    />
}

export default hot(module)(CategoryRestrictionSectionShadow);