import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { parserValueWithoutBrlCurrency } from "../../shared/helpers";
import { parseAmount } from "../../shared/balance_service";
import { grayFocusedStyles } from "./AdvanceScheduleCostAllocationField";

const AdvanceScheduleCostField = ({
  validating, smallScreen, currencies, onChangeCurrency, onChangeCost, initialValue
}) => {
  const [state, setState] = useState({
    currency: initialValue.currency ?? currencies[0].code,
    cost: initialValue.cost ?? ''
  });

  const handleCurrency = (event) => {
    const currency = event.target.value;
    setState({ ...state, currency: currency });
    onChangeCurrency(currency);
  }

  const handleCost = (event) => {
    const cost = parserValueWithoutBrlCurrency(event.target.value);
    setState({ ...state, cost: cost });
    onChangeCost(cost);
  }

  const error = validating && parseAmount(state.cost) === 0;

  return <Grid container mt={2} columnSpacing={2}>
    <Grid item xs={12}>
      <Typography variant="h6">
        3. Valor
      </Typography>
    </Grid>
    <Grid item xs={ smallScreen ? 12 : 6 } mt={2}>
      <FormControl fullWidth>
        <InputLabel id={"currency-select-label"}>Moeda</InputLabel>
        <Select
          disabled={currencies.length === 1}
          value={state.currency}
          onChange={handleCurrency}
          label={"Moeda"}
          labelId={"currency-select-label"}
        >
          {
            currencies.map((currency, index) => (
              <MenuItem
                key={index}
                value={currency.code}
              >
                { `${currency.code} - ${currency.name}` }
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={ smallScreen ? 12 : 6 } mt={2}>
      <TextField
        fullWidth
        label={"Valor"}
        value={state.cost}
        onChange={handleCost}
        placeholder={"0,00"}
        error={error}
        helperText={error && "Esse campo é obrigatório"}
        data-testid={"cost-field"}
        focused={true}
        sx={grayFocusedStyles}
      />
    </Grid>
  </Grid>
}

export default AdvanceScheduleCostField