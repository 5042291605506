import React, { useState, useEffect } from "react";
import BalanceProgressFeedback from "./BalanceProgressFeedback";
import { Button } from "@mui/material";
import { parserCurrencyBRLValue } from "../../../../shared/helpers";
import { deposit_admin_account_balance_statement_path } from "../../../../../routes";
import CloseIcon from "@mui/icons-material/Close";

const ProgressFeedbackDisplay = ({
  progressData,
  lastProcessedSubAccount,
  balanceDeficit,
  operation,
  adjustMethod,
  show,
}) => {
  const [
    balanceProgressFeedbackDescription,
    setBalanceProgressFeedbackDescription,
  ] = useState("");
  const [
    balanceProgressFeedbackAlertMessage,
    setBalanceProgressFeedbackAlertMessage,
  ] = useState("");
  const [
    balanceProgressFeedbackAlertSeverity,
    setBalanceProgressFeedbackAlertSeverity,
  ] = useState("");
  const [
    balanceProgressFeedbackAlertTitle,
    setBalanceProgressFeedbackAlertTitle,
  ] = useState("");
  const [balanceProgressFeedbackAction, setBalanceProgressFeedbackAction] =
    useState(null);
  const [isInsufficientBalance, setIsInsufficientBalance] = useState(
    balanceDeficit < 0
  );
  const [showProgressFeedback, setShowProgressFeedback] = useState(show);

  const isProcessing =
    progressData.progress > 0 && progressData.progress < progressData.total;
  const isFinished =
    progressData.progress === -1 ||
    (progressData.total && progressData.progress >= progressData.total) ||
    isInsufficientBalance;

  useEffect(() => {
    setShowProgressFeedback(show);
    setIsInsufficientBalance(false);
    setBalanceProgressFeedbackAction(null);

    const insufficientBalanceError =
      progressData.status === "insufficient_balance";

    if (balanceDeficit < 0) {
      setIsInsufficientBalance(true);
      setInsufficientBalanceProgressFeedback(balanceDeficit);
    } else if (isFinished && insufficientBalanceError) {
      setIsInsufficientBalance(true);

      setInsufficientBalanceProgressFeedback(Number(progressData.new_balance));
    } else if (isFinished) {
      setFinishedBalanceProgressFeedback();
    } else if (isProcessing) {
      setBalanceProgressFeedbackDescription("Status: Em processamento");
    }
  }, [balanceDeficit, progressData.progress]);

  const setFinishedBalanceProgressFeedback = () => {
    const { title, description, message, severity } =
      buildBalanceProgressFeedbackAlertData();

    setBalanceProgressFeedbackAlertTitle(title);
    setBalanceProgressFeedbackDescription(description);
    setBalanceProgressFeedbackAlertMessage(message);
    setBalanceProgressFeedbackAlertSeverity(severity);
  };

  const setInsufficientBalanceProgressFeedback = (balanceDeficit) => {
    setBalanceProgressFeedbackAlertTitle("Atenção!");
    setBalanceProgressFeedbackDescription("");
    setBalanceProgressFeedbackAlertMessage(
      insufficientBalanceMessage(balanceDeficit)
    );
    setBalanceProgressFeedbackAlertSeverity("warning");
    setBalanceProgressFeedbackAction(
      <Button color="inherit" onClick={redirectToDepositBalanceStatement}>
        Adicionar saldo
      </Button>
    );
  };

  /* istanbul ignore next */
  const redirectToDepositBalanceStatement = () => {
    window.location.assign(deposit_admin_account_balance_statement_path());
  };

  const insufficientBalanceMessage = (balanceDeficit) => {
    const balanceDeficitParsed = parserCurrencyBRLValue(
      Math.abs(balanceDeficit)
    );

    return `O saldo disponível não é suficiente para concluir a solicitação. Por favor, adicione ${balanceDeficitParsed}${
      adjustMethod === "manual" ? " " : " ou altere o arquivo "
    }e repita a ação.`;
  };

  const getSuccessProgressFeedbackAlert = () => {
    return {
      description:
        operation === "withdraw"
          ? "Seu resgate foi concluído!"
          : "Seu saldo foi ajustado com sucesso!",
      title: "Status",
      message: "Concluído com sucesso.",
      severity: "success",
    };
  };

  const getErrorProgressFeedbackAlert = () => {
    const message =
      adjustMethod == "manual"
        ? "Erro na definição de saldo, tente novamente."
        : "Erro no processamento do arquivo, faça um novo upload e tente novamente.";

    return {
      description: "Erro de processamento!",
      title: "Status",
      message: message,
      severity: "error",
    };
  };

  const getWarningProgressFeedbackAlert = () => {
    return {
      description: "Oops, não foi possível concluir o processo!",
      title: "Status",
      message: `A definição de saldo foi feita até a subconta ${lastProcessedSubAccount.name}`,
      severity: "warning",
    };
  };

  const buildBalanceProgressFeedbackAlertData = () => {
    if (
      progressData.status === "success" &&
      progressData.progress >= progressData.total
    ) {
      return getSuccessProgressFeedbackAlert();
    } else if (progressData.status === "error") {
      if (lastProcessedSubAccount?.id) {
        return getWarningProgressFeedbackAlert();
      } else {
        return getErrorProgressFeedbackAlert();
      }
    }

    return { description: "", title: "", message: "", severity: "" };
  };

  /* istanbul ignore next */
  const closeProgressFeedback = () => {
    setShowProgressFeedback(false);
  };

  if (
    !isInsufficientBalance &&
    (!progressData.progress || !balanceProgressFeedbackDescription)
  )
    return null;

  return (
    showProgressFeedback && (
      <BalanceProgressFeedback
        description={balanceProgressFeedbackDescription}
        progress={progressData.progress || 0}
        total={progressData.total}
        alertMessage={balanceProgressFeedbackAlertMessage}
        alertSeverity={balanceProgressFeedbackAlertSeverity}
        alertTitle={balanceProgressFeedbackAlertTitle}
        showLinearProgress={isProcessing && !isInsufficientBalance}
        showProgressMessage={
          balanceProgressFeedbackAlertSeverity !== "error" &&
          !isInsufficientBalance
        }
        action={
          <React.Fragment>
            {balanceProgressFeedbackAction}
            <CloseIcon
              data-testid="close-progress-feedback"
              sx={{ cursor: "pointer", marginLeft: "10px" }}
              onClick={closeProgressFeedback}
            />
          </React.Fragment>
        }
      />
    )
  );
};

export default ProgressFeedbackDisplay;
