const defaultList = [
  { id: "proven_purchase",      label: "Despesas comprovadas",             tabs: ["transactions"] },
  { id: "unproven_purchase",    label: "Despesas não comprovadas",         tabs: ["transactions"] },
  { id: "canceled",             label: "Despesas estornadas",              tabs: ["transactions"] },
  { id: "purchase_fee",         label: "Taxas de despesas internacionais", tabs: ["transactions"] },
  { id: "withdrawal",           label: "Saques",                           tabs: ["transactions"] },
  { id: "withdrawal_fee",       label: "Taxas de saque",                   tabs: ["transactions"] },
  { id: "drawback",             label: "Estornos",                         tabs: ["transfers"] },
  { id: "transfer_received",    label: "Recarga da conta",                 tabs: ["transfers"] },
  { id: "add_balance",          label: "Recarga da subconta",              tabs: ["transfers"] },
  { id: "ac_transfer_sent",     label: "Resgate da conta",                 tabs: ["transfers"] },
  { id: "ac_transfer_sent_fee", label: "Taxa de resgate da conta",         tabs: ["transfers"] },
  { id: "withdrawal_balance",   label: "Resgate da subconta",              tabs: ["transfers"] },
]

const types = {
    transaction_types: {
      label: "Tipo de transação",
      list: [
        ...defaultList,
        { id: "ac_income", label: "Rendimento da conta",    tabs: ["transfers"] },
        { id: "sb_income", label: "Rendimento da subconta", tabs: ["transfers"] },
      ],
    },
  };

  export const types_without_incomes = {
    transaction_types: {
      label: "Tipo de transação",
      list: defaultList,
    },
  };

  export const subscription_filters={
    transaction_types: {
      label: "Tipo de transação",
      list: [
        { id: "proven_purchase",      label: "Despesas comprovadas",             tabs: ["transactions"] },
        { id: "unproven_purchase",    label: "Despesas não comprovadas",         tabs: ["transactions"] },
        { id: "canceled",             label: "Despesas estornadas",              tabs: ["transactions"] },
        { id: "purchase_fee",         label: "Taxas de despesas internacionais", tabs: ["transactions"] },
        { id: "add_balance",          label: "Recarga da subconta",              tabs: ["transfers"] },
        { id: "withdrawal_balance",   label: "Resgate da subconta",              tabs: ["transfers"] },
      ],
    },
  }

  
  export default types;
  