import * as React from "react";
import {forwardRef, useImperativeHandle, useMemo, useState} from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TablePagination from "@mui/material/TablePagination";
import {EspressoTableBody} from "./EspressoTableBody";
import {EspressoTableHead} from "./EspressoTableHead";
import {EspressoTableToolbar} from "./EspressoTableToolbar";
import PropTypes from "prop-types";
import {EspressoTableFilter} from "./EspressoTableFilter";
import {Pagination, Skeleton, Stack} from "@mui/material";

export const EspressoTable = forwardRef((props, ref) => {
    const {
        data = [],
        currentPage = 1,
        totalCount = 0,
        perPage = 20,
        fixedPageSize = false,
        hoverRows,
        selectable,
        emptyMessage,
        columns,
        fetchPage,
        title,
        subtitle,
        actions = [],
        onClickRow,
    } = props;

    const [selectAll, setSelectAll] = useState(false);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(currentPage);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    const [loadingPage, setLoadingPage] = useState(false);
    const [rows, setRows] = useState(data);
    const [totalRows, setTotalRows] = useState(totalCount);
    const [filters, setFilters] = useState([]);
    const [showFilter, setShowFilter] = useState(false);

    const loadPage = async (page, perPage, filters) => {
        setLoadingPage(true);
        const pageData = await fetchPage(page, perPage, filters);
        setRows(pageData.data);
        setPage(pageData.currentPage);
        setRowsPerPage(pageData.perPage);
        setTotalRows(pageData.totalCount);
        setLoadingPage(false);
    };

    const handleSelectAll = (event) => {
        setSelectAll(event.target.checked);
        setSelected([]);
    };

    const handleSelect = (event, id) => {
        event.stopPropagation();

        const clickedIndex = selected.indexOf(id);
        let newSelected = [];

        if (clickedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else {
            newSelected = newSelected.concat(
                selected.slice(0, clickedIndex),
                selected.slice(clickedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handlePage = (_, newPage) => loadPage(
        fixedPageSize ? newPage : newPage + 1,
        rowsPerPage,
        filters
    );

    const handleRowsPerPage = (event) => loadPage(1, parseInt(event.target.value, 10), filters);

    const handleFilters = async (filters) => {
        setFilters(filters);
        await refresh(filters);
    }

    const selectedCount = selectAll ? totalRows - selected.length : selected.length;

    const handleAction = (onAction) => async () => {
        setLoadingPage(true);
        await onAction(selectAll, selected, selectedCount);
        setLoadingPage(false);
    }

    const filterable = useMemo(() => {
        return columns.some(column => column.filterable);
    }, [columns]);

    const refresh = async (filters) => {
        setSelectAll(false);
        setSelected([]);
        await loadPage(1, rowsPerPage, filters);
    }

    const toggleFilter = () => setShowFilter(!showFilter);

    useImperativeHandle(ref, () => ({refresh}), [filters]);

    return (
        <Box sx={{width: '100%', my: 4}}>
            {(rows.length === 0 && filters.length === 0) && (
                <Paper
                    data-testid={'table-empty-card'}
                    sx={{width: '100%', my: 4, p: 4, textAlign: 'center'}}>
                    {loadingPage ? (
                        <Skeleton variant="rounded" height={20}/>
                    ) : (
                        emptyMessage
                    )}
                </Paper>
            )}
            {(rows.length > 0 || filters.length > 0) && (
                <Paper sx={{width: '100%', mb: 2}}>
                    <Stack direction={'row'} alignItems={'stretch'}>
                        <Stack sx={{flex: 1}} justifyContent={'space-between'}>
                            <Stack>
                                <EspressoTableToolbar
                                    title={title}
                                    subtitle={subtitle}
                                    filterable={filterable}
                                    loading={loadingPage}
                                    selectedCount={selectedCount}
                                    selectable={selectable}
                                    actions={actions}
                                    showFilter={showFilter}
                                    onToggleFilter={toggleFilter}
                                    onAction={handleAction}/>
                                {rows.length === 0 && (
                                    <Box sx={{textAlign: 'center'}}>
                                        {loadingPage ? (
                                            <Skeleton variant="rounded" height={20}/>
                                        ) : (
                                            'A busca não retornou resultados. Tente alterar os filtros'
                                        )}
                                    </Box>
                                )}
                                {rows.length > 0 && (
                                    <TableContainer>
                                        <Table>
                                            <EspressoTableHead
                                                loading={loadingPage}
                                                selectable={selectable}
                                                selectedCount={selected.length}
                                                selectAll={selectAll}
                                                onSelectAll={handleSelectAll}
                                                totalRows={totalRows}
                                                columns={columns}/>
                                            <EspressoTableBody
                                                loading={loadingPage}
                                                hoverRows={hoverRows}
                                                page={page}
                                                rowsPerPage={rowsPerPage}
                                                rows={rows}
                                                onClickRow={onClickRow}
                                                onSelectRow={handleSelect}
                                                columns={columns}
                                                selectAll={selectAll}
                                                selected={selected}
                                                selectable={selectable}/>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Stack>
                            {!fixedPageSize && <TablePagination
                                disabled={loadingPage}
                                rowsPerPageOptions={[10, 20, 30]}
                                component="div"
                                count={totalRows}
                                rowsPerPage={rowsPerPage}
                                page={page - 1}
                                labelRowsPerPage={'Registros por página'}
                                labelDisplayedRows={(info) => `${info.from}-${info.to} de ${info.count}`}
                                onPageChange={handlePage}
                                onRowsPerPageChange={handleRowsPerPage}/>}
                            {fixedPageSize && <Pagination
                                page={page}
                                shape={"rounded"}
                                disabled={loadingPage}
                                onChange={handlePage}
                                count={Math.ceil(totalRows / rowsPerPage)}
                                sx={{m: 2, alignSelf: 'flex-end'}}/>}

                        </Stack>
                        <EspressoTableFilter
                            show={showFilter}
                            columns={columns}
                            onChange={handleFilters}/>
                    </Stack>
                </Paper>
            )}
        </Box>
    );
});

export const BistroTableAction = ({id, label, icon, variant, onClick, tooltip, width}) => {
    return {id, label, icon, variant, onClick, tooltip, width};
}

export const BistroTableCell = ({value}) => {
    return {value};
}

export const BistroTableRow = ({id, data, cells}) => {
    return {id, data, cells};
}

export const BistroTableColumn = (args) => {
    const {
        id,
        padding,
        label,
        align = 'center',
        filterable = false,
        filterType = 'text',
        values = [],
        cellBuilder = (cell) => cell.value,
    } = args;
    return {id, padding, label, align, filterable, filterType, values, cellBuilder};
}


EspressoTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    currentPage: PropTypes.number,
    totalCount: PropTypes.number,
    perPage: PropTypes.number,
    hoverRows: PropTypes.bool,
    selectable: PropTypes.bool,
    emptyMessage: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object),
    fetchPage: PropTypes.func,
    title: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.object),
    onClickRow: PropTypes.func
}