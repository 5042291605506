import React, {useState} from "react";
import {ErpTemplateConfig} from "../ErpTemplateConfig";
import {SankhyaCostCenterConfigSteps} from "./SankhyaCostCenterConfigSteps";
import {SankhyaCostCenterConfigForm} from "./SankhyaCostCenterConfigForm";
import {updateTemplate} from "../../erp_template_connector";

export const SankhyaCostCenterConfig = ({template}) => {
    const [field, setField] = useState(template?.settings?.field ?? '')
    const [value, setValue] = useState(template?.settings?.value ?? '')
    const [frequency, setFrequency] = useState(template?.frequency ?? 'daily')
    const [restricted, setRestricted] = useState(template?.settings?.restricted || false)

    const changeData = ({field, value, frequency, restricted}) => {
        setField(field)
        setValue(value)
        setFrequency(frequency)
        setRestricted(restricted)
    }

    return <ErpTemplateConfig
        erp={'sankhya'}
        title={'Configuração de centros de custo'}
        validConfig={field.length > 0 && value.length > 0}
        onConfigure={() => updateTemplate('sankhya', 'cost_center', {field, value, restricted}, frequency)}
        steps={<SankhyaCostCenterConfigSteps/>}
        form={(loading, failed) => (
            <SankhyaCostCenterConfigForm
                data={{field, value, frequency, restricted}}
                onChangeData={changeData}
                failed={failed}
                disabled={loading}/>
        )}/>
}
