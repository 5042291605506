import { Button, CardHeader, Dialog, DialogActions, DialogContent, Divider, InputAdornment, LinearProgress, ListItemButton, Pagination, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchTagsBy } from "./advance_schedules_service";
import SearchIcon from '@mui/icons-material/Search';

const AdvanceScheduleTagsGroupModal = ({ open, tagsGroup, onClose, onSelect }) => {
  const [state, setState] = useState({
    loading: false,
    data: [],
    pages: 1,
    search: '',
    error: false
  });

  const [triggers, setTriggers] = useState({
    page: 1,
    search: ''
  });

  const fetchTags = async () => {
    setState({ ...state, loading: true, error: false });
    const body = await fetchTagsBy(tagsGroup.id, triggers.page, triggers.search);
    if (body !== null) {
      setState({ ...state, loading: false, data: body.data, pages: body.pages, error: false });
    } else {
      setState({ ...state, loading: false, data: [], pages: 0, error: true });
    }
  }

  const handlePage = (_, value) => {
    setTriggers({ ...triggers, page: value });
  }

  const handleSearch = (event) => {
    setState({ ...state, search: event.target.value });
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setTriggers({ ...triggers, page: 1, search: event.target.value });
    }
  }
  
  useEffect(() => {
    fetchTags();
  }, [triggers]);

  return <Dialog
    open={open}
    maxWidth={"md"}
    fullWidth={true}
    disableAutoFocus={true}
    data-testid={"tags-group-dialog"}
  >
    <CardHeader
      title={tagsGroup.name}
      titleTypographyProps={{ variant: "h6" }}
      sx={{ pb: 0, mx: 1 }}
    />
    <DialogContent>
      <TextField
        fullWidth
        sx={{ mb: 2 }}
        label="Buscar"
        placeholder="Busque pela tag"
        data-testid={"search-tag-field"}
        value={state.search}
        onChange={handleSearch}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon/>
            </InputAdornment>
          ),
        }}
      />
      { state.loading && <LinearProgress sx={{ mt: 2 }}/> }
      { 
        state.error &&
        <Typography color={"error"}>
          Erro ao buscar tags. Tente novamente.
        </Typography>
      }
      {
        state.data.map((tag, index) => (
          <Stack key={index}>
            <ListItemButton onClick={() => onSelect(tag)}>
              {tag.name}
            </ListItemButton>
            <Divider/>
          </Stack>
        ))
      }
      {
        state.pages > 0 &&
        <Stack
          alignItems={"end"}
          sx={{ mt: 2 }}
        >
          <Pagination
            shape="rounded"
            color="primary"
            count={state.pages}
            page={triggers.page}
            onChange={handlePage}
            data-testid={"pagination"}
          />
        </Stack>
      }
    </DialogContent>
    <DialogActions>
      <Button
        color={"inherit"}
        onClick={onClose}
        data-testid={"cancel-button"}
      >
        CANCELAR
      </Button>
    </DialogActions>
  </Dialog>
}

export default AdvanceScheduleTagsGroupModal;