import React from "react";
import {
  CardContent,
  Checkbox,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  CardActions,
} from "@mui/material";
import { parserValueWithBrlCurrencyAllowZero } from "../../../../shared/helpers";
import { closeModal } from "../../../../shared/jquery_wrapper";

import DownloadImportList from "./DownloadImportList";
import SelectFileInput from "./SelectFileInput";

const manualAdjust = (
  amount,
  setAmount,
  operation,
  withdrawAll,
  setWithdrawAll
) => (
  <React.Fragment>
    <Typography sx={{ fontSize: 16 }} gutterBottom>
      Selecione na lista as subcontas e confirme o valor:
    </Typography>
    <TextField
      data-testid="balance-amount-input"
      sx={{ mt: 1, width: "100%", maxWidth: "576px" }}
      label="Valor (R$)"
      variant="outlined"
      size="medium"
      value={amount}
      onChange={(event) =>
        setAmount(parserValueWithBrlCurrencyAllowZero(event.target.value))
      }
      disabled={withdrawAll}
      placeholder="R$ 0,00"
    />
    {operation == "withdraw" && (
      <FormControlLabel
        sx={{ marginTop: 2, width: "100%" }}
        control={
          <Checkbox
            checked={withdrawAll}
            onChange={(e) => setWithdrawAll(e.target.checked)}
          />
        }
        label="Resgatar tudo"
      />
    )}
  </React.Fragment>
);

const uploadAdjust = (allSubAccounts, operation, file, setFile) => (
  <React.Fragment>
    <Typography sx={{ fontSize: 16, marginBottom: 3 }}>
      Faça o download do arquivo e preencha com os dados necessários para a
      Importação
    </Typography>
    <DownloadImportList allSubAccounts={allSubAccounts} operation={operation} />
    <Typography sx={{ fontSize: 16, marginBottom: 3, marginTop: 3 }}>
      Já preencheu o arquivo?
    </Typography>
    <Typography sx={{ fontSize: 16, marginBottom: 3 }}>
      Clique no campo abaixo para localizá-lo e faça o upload
    </Typography>
    <SelectFileInput file={file} setFile={setFile} />
    <Typography sx={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }} gutterBottom>
      Atenção: Importação máxima de 500 linhas por arquivo
    </Typography>
  </React.Fragment>
);

const BalanceForm = ({
  balance,
  modal = false,
  amount,
  setAmount,
  operation = "deposit",
  setOperation,
  withdrawAll,
  setWithdrawAll,
  processing,
  operationProcessing,
  adjustMethod = "manual",
  setAdjustMethod,
  allSubAccounts,
  file,
  setFile,
}) => {
  const handleOperationChange = (event) => {
    const newOperation = event.target.value;
    setOperation(newOperation);

    if (newOperation !== "withdraw") {
      setWithdrawAll(false);
    }
  };

  return (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Saldo disponível para alocação
        </Typography>
        <Typography
          mb={3}
          variant="h5"
          component="div"
          style={{ color: "#3D0079" }}
        >
          {balance}
        </Typography>

        <Typography sx={{ fontSize: 16 }} gutterBottom>
          {processing
            ? "Opção em processamento:"
            : "Selecione uma das opções para definir o saldo:"}
        </Typography>
        <FormControl
          component="fieldset"
          sx={{ marginBottom: 3, marginLeft: 2, marginTop: 2 }}
        >
          <RadioGroup
            row
            aria-label="saldo"
            name="row-radio-buttons-group"
            value={processing ? operationProcessing : operation}
            onChange={handleOperationChange}
            disabled={processing}
          >
            <FormControlLabel
              sx={{ marginRight: 12 }}
              value="deposit"
              control={<Radio />}
              label="Adicionar saldo"
              disabled={processing}
            />
            <FormControlLabel
              sx={{ marginRight: 12 }}
              value="adjust"
              control={<Radio />}
              label="Ajustar saldo"
              disabled={processing}
            />
            <FormControlLabel
              sx={{ marginRight: 12 }}
              value="withdraw"
              control={<Radio />}
              label="Resgatar saldo"
              disabled={processing}
            />
          </RadioGroup>
        </FormControl>

        <Typography sx={{ fontSize: 16 }} gutterBottom>
          {processing
            ? "Definição de saldo em processamento:"
            : "Selecione a definição de saldo por subcontas ou por upload do arquivo:"}
        </Typography>
        <FormControl
          component="fieldset"
          sx={{ marginBottom: 3, marginLeft: 2, marginTop: 2 }}
        >
          <RadioGroup
            row
            aria-label="ajuste"
            name="row-radio-buttons-group"
            value={adjustMethod}
            onChange={(event) => setAdjustMethod(event.target.value)}
            disabled={processing}
          >
            <FormControlLabel
              sx={{ marginRight: 12 }}
              value="manual"
              control={<Radio />}
              label="Selecionar subcontas"
              disabled={processing}
            />
            <FormControlLabel
              sx={{ marginRight: 12 }}
              value="upload"
              control={<Radio />}
              label="Upload de arquivo"
              disabled={processing}
            />
          </RadioGroup>
        </FormControl>

        {!processing &&
          (adjustMethod === "manual"
            ? manualAdjust(
                amount,
                setAmount,
                operation,
                withdrawAll,
                setWithdrawAll
              )
            : uploadAdjust(allSubAccounts, operation, file, setFile))}
      </CardContent>
      {modal && (
        <CardActions sx={{ justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="text"
            size="large"
            sx={{ color: "#000000" }}
            onClick={() =>
              closeModal("#js-modal-sub-account-change-balance.modal")
            }
          >
            Cancelar
          </Button>
        </CardActions>
      )}
    </React.Fragment>
  );
};

export default BalanceForm;
