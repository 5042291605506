import React from "react";
import BistroFeaturesRepository from "./bistro_features_repository";
import {initSelects, showSnackbar} from "../../shared/jquery_wrapper";

const PLANS_LEVELS = 4;

export default class BistroFeatures extends React.Component {

    constructor(props) {
        super(props);
        this.repository = new BistroFeaturesRepository(this.props.featuresEndpoint)
        this.state = {
            groups: []
        }
    }

    async componentDidMount() {
        await this.loadFeatures()
    }

    loadFeatures = async () => {
        try {
            let response = await this.repository.getFeatures()
            this.setState({...this.state, groups: response.data})
            initSelects();
        } catch (e) {
            showSnackbar({
                content: "Não foi possível carregar as funcionalidades",
                style: "alert",
                timeout: 3000
            })
        }
    }

    updateFeature = async (feature, alwaysActive) => {
        try {
            let response = await this.repository.updateFeature(feature, alwaysActive)
            this.setState({...this.state, groups: response.data})
        } catch (e) {
            showSnackbar({
                content: "Não foi possível editar a funcionalidade",
                style: "alert",
                timeout: 3000
            })
        }
    }

    toggleFeaturePlan = async (feature, planLevel, enable) => {
        try {
            let response;
            if (enable) {
                response = await this.repository.enablePlan(feature, planLevel);
            } else {
                response = await this.repository.disablePlan(feature, planLevel);
            }
            this.setState({...this.state, groups: response.data});
        } catch (e) {
            showSnackbar({
                content: "Não foi possível editar a funcionalidade",
                style: "alert",
                timeout: 3000
            })
        }
    }

    render() {
        return <div className="container">
            <div className="title-container">
                <div className="col s12">
                    <a href={this.props.plansEndpoint}><i className='mdi mdi-arrow-left back'/></a>
                    <h1>Gestão de funcionalidades</h1>
                </div>
            </div>
            <div className="row valign-wrapper">
                <div className="col l3 offset-l4 center-align">Funcionalidade sempre ativa?</div>
                <div className="col l5 center-align">Disponível nos planos:</div>
            </div>
            {this.state.groups.map((group, idx) => this.renderFeatureGroup(idx, group))}
        </div>
    }

    renderFeatureGroup(index, group) {
        return <section key={index}>
            <h5>{group.attributes.group_name}</h5>
            <div className="collection z-depth-1 white">
                {group.attributes.features.map((feature, fIndex) => this.renderFeature(fIndex, feature))}
            </div>
        </section>;
    }

    renderFeature(index, feature) {
        return <div key={index} className="row valign-wrapper no-bottom-margin">
            <div className="col l4 m3 s6">{feature.title}</div>
            <div className="input-field col l3 m3 s6" style={{margin: "0px"}}>
                {this.renderAlwaysActiveSelector(feature)}
            </div>
            <div className="col l5 m6 s12 row no-bottom-margin">
                {this.renderPlanAvailabilities(feature)}
            </div>
        </div>;
    }

    renderAlwaysActiveSelector(feature) {
        return <div style={{width: "80px"}}>
            <select value={feature.always_active ? '1' : '0'}
                    onChange={async (event) => {
                        await this.updateFeature(feature, event.target.value === '1')
                    }}>
                <option value={'0'}>Não</option>
                <option value={'1'}>Sim</option>
            </select>
        </div>;
    }

    renderPlanAvailabilities(feature) {
        return Array.from({ length: PLANS_LEVELS }, (_, idx) =>
            <div key={idx} className="col s2">
                <label>
                    <input className="filled-in"
                           type="checkbox"
                           checked={feature.plans.find((plan) => plan.level === idx + 1) !== undefined}
                           onChange={async (event) => {
                               await this.toggleFeaturePlan(feature, idx + 1, event.target.checked)
                           }}
                    />
                    <span>{idx + 1}</span>
                </label>
            </div>
        );
    }
}
