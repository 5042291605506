import React, { useState } from 'react';
import {download_admin_file_export_configuration_path} from "../../../../routes";
import { ShadowTheme } from '../../ShadowTemplate';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { showSnackbar } from "../../../shared/jquery_wrapper";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { hot } from 'react-hot-loader';
import {downloadFile} from "../../../shared/helpers";

const DownloadModalShadow = (props) => {
  return <ShadowTheme rootSelector={props.rootSelector}>
    <DownloadModal integrationId={props.integrationId} />
  </ShadowTheme>
}

const DownloadModal = (props) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [validating, setValidating] = useState(false);
  const maxDate = dayjs();

  const handleSubmit = async () => {
    if (!validateFields()) {
      return;
    }

    const response = await fetch(`${download_admin_file_export_configuration_path(props.integrationId)}?start_date=${startDate}&end_date=${endDate}`, {
      method: 'GET',
    });

    if (!response.ok) {
      const body = await response.json();
      showSnackbar({ content: body.notice, style: "notice" })
    } else {
      const body = await response.blob();
      await downloadFile(body, "download.csv");
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleStartDate = (value) => {
    setStartDate(dayjs(value).format('YYYY-MM-DD'));
  };

  const handleEndDate = (value) => {
    setEndDate(dayjs(value).format('YYYY-MM-DD'));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateDate = (date) => {
    return dayjs(date).isValid();
  };

  const validateDateRange = () => {
    const endDateConverted = dayjs(endDate);
    const startDateConverted = dayjs(startDate);
    const valid = !endDateConverted.isBefore(startDateConverted) && endDateConverted.diff(startDateConverted, 'month', true) <= 1;

    if (!valid) {
      showSnackbar({ content: dateRangeError(), style: "notice" });
    }
    return valid;
  }

  const dateRangeError = () => {
    const endDateConverted = dayjs(endDate);
    const startDateConverted = dayjs(startDate);

    if (endDateConverted.isBefore(startDateConverted)) {
      return 'A data do fim não pode anteceder a data de início';
    }
    return 'O período não pode ser maior que 1 mês';
  }

  const validateFields = () => {
    setValidating(true);
    return validateDate(startDate) && validateDate(endDate) && validateDateRange();
  }

  return (
    <>
      <Box display='flex'>
        <IconButton onClick={handleOpen} style={{ padding: 0 }} data-testid={"download-button"} disableRipple>
          <DownloadIcon style={{ color: 'black', fontSize: '22px' }} />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" data-testid={'dialog-modal'} fullWidth>
        <DialogTitle>Defina o período</DialogTitle>
        <DialogContent style={ { paddingTop: 10 } }>
          <Box display='flex' flexDirection='row' justifyContent='space-around'>
          <LocalizationProvider
            adapterLocale="pt-br"
            dateAdapter={AdapterDayjs}
          >
            <DatePicker
              disableHighlightToday
              maxDate={maxDate}
              label="Data Inicial"
              format={'DD/MM/YYYY'}
              value={dayjs(startDate)}
              onChange={handleStartDate}
              slots={{ openPickerIcon: ArrowDropDownIcon }}
              slotProps={{
                textField: {
                  error: validating && !validateDate(startDate),
                  helperText: validating && !validateDate(startDate) &&
                              "Defina a data inicial"
                }
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider
            adapterLocale="pt-br"
            dateAdapter={AdapterDayjs}
          >
            <DatePicker
              disableHighlightToday
              minDate={startDate ? dayjs(startDate) : null}
              maxDate={startDate && dayjs(startDate).add(1, 'month') < dayjs() ? dayjs(startDate).add(1, 'month') : maxDate }
              label="Data final"
              format={'DD/MM/YYYY'}
              value={dayjs(endDate)}
              onChange={handleEndDate}
              slots={{ openPickerIcon: ArrowDropDownIcon }}
              slotProps={{
                textField: {
                  error: validating && !validateDate(endDate),
                  helperText: validating && !validateDate(endDate) &&
                              "Defina a data final"
                }
              }}
            />
          </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            size="large"
            sx={{color: '#000000'}}
            onClick={handleClose}
            data-testid={"cancel-button"}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            data-testid={"submit-download-button"}
          >
            <DownloadIcon style={{ fontSize: '22px', marginRight: '5px' }} />
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default hot(module)(DownloadModalShadow);
