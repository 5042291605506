import React, {useState} from "react";

import {Snackbar, Stack} from "@mui/material";
import Container from "@mui/material/Container";
import {
    admin_classifications_category_path,
    admin_classifications_category_subcategory_path
} from "../../../../../routes";
import {PageNavbar} from "../../../components/PageNavbar";
import EspressoTemplate from "../../../EspressoTemplate";
import ClassificationRestrictionCard from "./components/ClassificationRestrictionCard";
import {fetchCostCenters, fetchCustomFields, fetchTagsGroups} from "../../classifications_service";
import {FormattedMessage as Intl} from "react-intl";
import {hot} from "react-hot-loader";

function Index(props) {
    const {
        companyCategory,
        subcategory,
        restrictions,
        hasCostCenter,
        hasTagsGroup,
        hasCustomField
    } = props;
    const [error, setError] = useState('');

    return <EspressoTemplate {...props}>
        <Stack>
            <PageNavbar
                backTo={backTo(companyCategory, subcategory)}
                title={`${subcategory?.name ?? companyCategory.name} - Restrições`}/>
            <Container maxWidth={'lg'} sx={{my: 5}}>
                <Stack>
                    {hasCostCenter && (
                        <ClassificationRestrictionCard
                            categoryId={companyCategory.categoryId}
                            subcategoryId={subcategory?.id}
                            restriction={restrictions.costCenter}
                            fetchItems={(page, search) => fetchCostCenters(page, search)}
                            searchError={<Intl id={'restriction.cost_center_card.search_error'}/>}
                            onError={() => setError('restriction.cost_center_card.restrict_error')}
                            type={'cost_center'}/>
                    )}
                    {hasTagsGroup && (
                        <ClassificationRestrictionCard
                            categoryId={companyCategory.categoryId}
                            subcategoryId={subcategory?.id}
                            restriction={restrictions.tagsGroup}
                            fetchItems={(page, search) => fetchTagsGroups(page, search)}
                            searchError={<Intl id={'restriction.tags_group_card.search_error'}/>}
                            onError={() => setError('restriction.tags_group_card.restrict_error')}
                            type={'tags_group'}/>
                    )}
                    {hasCustomField && (
                        <ClassificationRestrictionCard
                            categoryId={companyCategory.categoryId}
                            subcategoryId={subcategory?.id}
                            restriction={restrictions.customField}
                            fetchItems={(page, search) => fetchCustomFields(page, search)}
                            searchError={<Intl id={'restriction.custom_field_card.search_error'}/>}
                            onError={() => setError('restriction.custom_field_card.restrict_error')}
                            type={'custom_field'}/>
                    )}
                </Stack>
                {!!error && <Snackbar
                    open={!!error}
                    message={<Intl id={error}/>}
                    autoHideDuration={2000}
                    onClose={() => setError('')}/>}
            </Container>
        </Stack>
    </EspressoTemplate>
}

function backTo(companyCategory, subcategory) {
    if (subcategory) {
        return admin_classifications_category_subcategory_path(companyCategory.id, subcategory.id);
    }
    return admin_classifications_category_path(companyCategory.id);
}

export default hot(module)(Index)