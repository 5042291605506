import React from "react";

import {Stack} from "@mui/material";
import Container from "@mui/material/Container";
import {hot} from "react-hot-loader";
import {OmieCostCenterConfig} from "./components/omie/OmieCostCenterConfig";
import {OmieUserConfig} from "./components/omie/OmieUserConfig";
import {OmieProjectConfig} from "./components/omie/OmieProjectConfig";
import {SankhyaUserConfig} from "./components/sankhya/SankhyaUserConfig";
import {SankhyaCostCenterConfig} from "./components/sankhya/SankhyaCostCenterConfig";
import {SankhyaProjectConfig} from "./components/sankhya/SankhyaProjectConfig";
import EspressoTemplate from "../../EspressoTemplate";
import {PageNavbar} from "../../components/PageNavbar";
import {admin_erp_integration_template_events_path} from "../../../../routes";

const Show = (props) => {
  const {erp, templateType, integration, template} = props;

  const configMapping = {
    sankhya: {
      user: <SankhyaUserConfig template={template}/>,
      cost_center: <SankhyaCostCenterConfig template={template}/>,
      project: <SankhyaProjectConfig template={template}/>
    },
    omie: {
      user: <OmieUserConfig integration={integration} template={template}/>,
      cost_center: <OmieCostCenterConfig integration={integration} template={template}/>,
      project: <OmieProjectConfig integration={integration} template={template}/>
    },
  };

  return <EspressoTemplate {...props}>
    <Stack>
      <PageNavbar
        title={'Configuração'}
        backTo={admin_erp_integration_template_events_path(erp, templateType)}/>
      <Container maxWidth={'lg'} sx={{my: 5}}>
        {configMapping[erp]?.[templateType]}
      </Container>
    </Stack>
  </EspressoTemplate>
}


export default hot(module)(Show)