import React from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

import { Button } from "@mui/material";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

// Definir o formatter fora do componente para evitar recriação
const dateFormatter = new Intl.DateTimeFormat("pt-BR", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
});

const DownloadImportList = ({ allSubAccounts, operation }) => {
  /* istanbul ignore next */ //already tested if saveAs is called with the correct parameters
  const getFileName = () => {
    const formattedDate = dateFormatter
      .format(new Date())
      .replace(/[\/:\s]/g, "_");
    return `template_de_definicao_de_saldo_em_massa_${formattedDate}.xlsx`;
  };

  const exportExcel = async () => {
    const operationDescription = {
      adjust: "NOVO SALDO (R$)",
      deposit: "VALOR A SER ADICIONADO (R$)",
      withdraw: "VALOR A SER RESGATADO (R$)",
    };
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Subcontas");
    worksheet.columns = [
      { header: "ID SUBCONTA", key: "id" },
      { header: "USUÁRIO", key: "name", width: 50 },
      {
        header: "SALDO ATUAL",
        key: "balance",
        width: 20,
        style: { numFmt: "R$ #,##0.00" },
      },
      {
        header: operationDescription[operation],
        key: "newBalance",
        width: 40,
        style: { numFmt: "R$ #,##0.00" },
      },
    ];

    const data = allSubAccounts.map((subAccount) => ({
      id: subAccount.id,
      name: subAccount.name,
      balance: subAccount.balance,
      newBalance: "",
    }));

    data.forEach((subAccount) => {
      worksheet.addRow(subAccount);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    /* istanbul ignore next */ //already tested if saveAs is called with the correct parameters
    saveAs(new Blob([buffer]), getFileName());
  };

  return (
    <Button
      data-testid="download-import-list"
      sx={{ color: "black", border: "1px solid" }}
      startIcon={<CloudDownloadIcon />}
      onClick={exportExcel}
    >
      download do modelo
    </Button>
  );
};

export default DownloadImportList;
