import React, { useState } from "react";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Avatar, Button, Divider, Grid, LinearProgress, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { parserCurrencyBRLValue } from "../../shared/helpers";
import { cancelAdvanceSchedule } from "./advance_schedules_service";
import { admin_advances_path, edit_admin_advance_schedule_path, admin_advance_schedule_activity_logs_path } from "../../../routes";

const AdvanceScheduleListItem = ({ schedule, smallScreen, onRefresh, expanded = false }) => {
  const [loading, setLoading] = useState(false);

  const statusName = (status) => {
    if (status === 0) {
      return 'Expirado';
    } else if (status === 1) {
      return 'Ativo';
    }
    return 'Cancelado';
  }

  const statusColor = (status) => {
    if (status === 0) {
      return 'error';
    } else if (status === 1) {
      return 'green';
    }
    return 'grey';
  }

  const handleCancel = async () => {
    setLoading(true);
    await cancelAdvanceSchedule(
      schedule.id,
      () => onRefresh(),
    );
    setLoading(false);
  }

  return <Stack
    data-testid={"advance-schedule-item"}
  >
    <Accordion elevation={0} defaultExpanded={expanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ ml: 1 }}/>}>
        <ListItem disablePadding>
          <ListItemAvatar sx={{ color: "GrayText", fontSize: "20px" }}>
            { `#${schedule.number}` }
          </ListItemAvatar>
          <ListItemText
            primary={schedule.reason}
            secondary={
              <Typography
                variant="body2"
                color={statusColor(schedule.status)}
              >
                { statusName(schedule.status) }
                {
                  schedule.advance &&
                  <Typography
                    variant="body2"
                    component={"span"}
                    color={"grey"}
                  >
                    { ` - Última recarga ${schedule.advance.creation}` }
                  </Typography>
                }
              </Typography>
            }
          />
        </ListItem>
      </AccordionSummary>
      <AccordionDetails>
        <ListItem disablePadding>
          <ListItemAvatar children={<Avatar src={schedule.user.photo}/>}/>
          <ListItemText primary={schedule.user.name} secondary={schedule.user.email}/>
        </ListItem>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={ smallScreen ? 12 : 3 }>
            <ListItemText
              primary={"Data de expiração"}
              secondary={schedule.expiration ?? "Não definida"}
            />
          </Grid>
          <Grid item xs={ smallScreen ? 12 : 2 }>
            <ListItemText
              primary={"Moeda"}
              secondary={schedule.currency}
            />
          </Grid>
          <Grid item xs={ smallScreen ? 12 : 3 }>
            <ListItemText
              primary={"Valor da recorrência"}
              secondary={parserCurrencyBRLValue(schedule.cost, false)}
            />
          </Grid>
          <Grid item xs={ smallScreen ? 12 : 4 }>
            <ListItemText
              primary={"Valor do último registro de recorrência"}
              secondary={ schedule.advance ? parserCurrencyBRLValue(schedule.advance.cost, false) : "Não definido" }
            />
          </Grid>
        </Grid>
        { loading &&
          <Grid item xs={12}>
            <LinearProgress sx={{ mt: 3 }}/>
          </Grid>
        }
      </AccordionDetails>
      <AccordionActions
        sx={{
          gap: 1,
          flexDirection: smallScreen && "column",
          alignItems: smallScreen && "flex-start"
        }}
      >
        {
          schedule.status !== -1 &&
          <Button
            color={"error"}
            data-testid={"cancel-advance-schedule"}
            onClick={handleCancel}
            disabled={loading}
          >
            CANCELAR RECORRÊNCIA
          </Button>
        }
        <Button
          disabled={loading}
          data-testid={"actions-history"}
          href={admin_advance_schedule_activity_logs_path(schedule.id)}
        >
          HISTÓRICO DE AÇÕES
        </Button>
        <Button
          data-testid={"user-history"}
          disabled={loading}
          href={admin_advances_path({ filter: { advance_schedule_ids: [schedule.id] } })}
        >
          HISTÓRICO DO USUÁRIO
        </Button>
        {
          schedule.status !== -1 &&
          <Button
            variant={"contained"}
            data-testid={"edit"}
            disabled={loading}
            href={edit_admin_advance_schedule_path(schedule.id)}
          >
            EDITAR
          </Button>
        }
      </AccordionActions>
    </Accordion>
    <Divider/>
  </Stack>
}

export default AdvanceScheduleListItem;