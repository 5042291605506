import { Grid, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchCustomFieldsBy, fetchTagsGroupsBy } from "./advance_schedules_service";
import AdvanceScheduleTagsGroupField from "./AdvanceScheduleTagsGroupField";
import AdvanceScheduleCustomField from "./AdvanceScheduleCustomField";
import AdvanceScheduleCostAllocationField from "./AdvanceScheduleCostAllocationField";

const AdvanceScheduleClassifications = ({
  validating, smallScreen, companyUserId, onFetchTagsGroups, onSelectTag,
  onFetchCustomFields, onChangeCustomField, costAllocation, onSelectCostCenters, initialValue
}) => {
  const [loading, setLoading] = useState(false);
  const [tagsGroups, setTagsGroups] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [anyAccessibleCostCenter, setAnyAccessibleCostCenter] = useState(true);

  const fetchCustomFields = async () => {
    const body = await fetchCustomFieldsBy(companyUserId);
    if (body !== null) {
      setCustomFields(body.data);
      onFetchCustomFields(body.data);
    }
  }

  const fetchTagsGroups = async () => {
    const body = await fetchTagsGroupsBy(companyUserId);
    if (body !== null) {
      setTagsGroups(body.data);
      onFetchTagsGroups(body.data);
    }
  }

  const fetchClassifications = async () => {
    setLoading(true);
    await fetchCustomFields();
    await fetchTagsGroups();
    setLoading(false);
  }

  useEffect(() => {
    fetchClassifications();
  }, []);

  const showCostAllocation = costAllocation && anyAccessibleCostCenter;
  const showClassifications = tagsGroups.length > 0 || customFields.length > 0 || showCostAllocation;

  return <Grid container mt={2} spacing={2}>
    {
      showClassifications &&
      <Grid item xs={12}>
        <Typography variant="h6">
          4. Classificações
        </Typography>
      </Grid>
    }
    {
      loading &&
      <Grid item xs={12}>
        <LinearProgress />
      </Grid>
    }
    {
      showCostAllocation &&
      <Grid item xs={12}>
        <AdvanceScheduleCostAllocationField
          validating={validating}
          companyUserId={companyUserId}
          costAllocation={costAllocation}
          onSelectCostCenters={onSelectCostCenters}
          initialValue={initialValue.costCenters}
          onChangeAnyAccessibleCostCenter={setAnyAccessibleCostCenter}
        />
      </Grid>
    }
    {
      customFields.map((customField, index) => (
        <Grid item xs={ smallScreen ? 12 : 6 } key={index}>
          <AdvanceScheduleCustomField
            validating={validating}
            customField={customField}
            onChange={(value) => onChangeCustomField(customField.id, value)}
            initialValue={initialValue.customFields?.[customField.id]}
          />
        </Grid>
      ))
    }
    {
      tagsGroups.map((tagsGroup, index) => (
        <Grid item xs={ smallScreen ? 12 : 6 } key={index}>
          <AdvanceScheduleTagsGroupField
            validating={validating}
            tagsGroup={tagsGroup}
            onSelect={(tag) => onSelectTag(tagsGroup.id, tag)}
            initialValue={initialValue.tags?.find(tag => tag.tagsGroupId === tagsGroup.id)}
          />
        </Grid>
      ))
    }
  </Grid>
}

export default AdvanceScheduleClassifications;