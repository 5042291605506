import Paper from "@mui/material/Paper";
import {Avatar, Button, CardActions, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import Switch from "@mui/material/Switch";
import React from "react";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import DirectionsSubwayIcon from "@mui/icons-material/DirectionsSubway";
import HotelIcon from "@mui/icons-material/Hotel";
import VolunteeringActivismIcon from "@mui/icons-material/VolunteerActivism";
import SpeedIcon from "@mui/icons-material/Speed";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {FormattedMessage as Intl} from "react-intl";

export default function CategoryRestrictionCard({type, restricted, category, onAction, onSwitch}) {
    const accessible = !restricted || category.allSubcategories || category.subcategories.length > 0;

    return <Paper sx={{mt: 1}}>
        <ListItem
            secondaryAction={<Switch
                checked={accessible}
                onChange={onSwitch}
                inputProps={{'aria-label': category.name}}
            />}>
            <ListItemAvatar>
                <Avatar sx={{bgcolor: '#d2d3ff', color: '#6566a6'}}>
                    {categoryIcon(category)}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={category.name}
                secondary={categorySubtitle(type, restricted, accessible, category)}/>
        </ListItem>
        {accessible && category.hasSubcategories && <CardActions sx={{justifyContent: 'flex-end'}}>
            <Button variant={'text'} onClick={onAction}>
                Restringir subcategorias
            </Button>
        </CardActions>}
    </Paper>
}


const categoryIcon = (category) => {
    const icons = {
        'mdi-food': <FastfoodIcon/>,
        'mdi-subway-variant': <DirectionsSubwayIcon/>,
        'mdi-bed': <HotelIcon/>,
        'mdi-hand-heart': <VolunteeringActivismIcon/>,
        'mdi-speedometer': <SpeedIcon/>,
        'mdi-dots-horizontal': <MoreHorizIcon/>
    }
    return icons[category.icon];
}

const categorySubtitle = (type, restricted, accessible, category) => {
    const hasAccess = category.allSubcategories || !restricted;
    if (hasAccess && category.inaccessible.length === 0) {
        return <Intl id={"restriction.category_card.subtitle.all"}/>
    }
    if (hasAccess && category.inaccessible.length > 0) {
        const except = subcategoriesList(category.inaccessible);
        return <Intl id={`restriction.except`} values={{except}}/>;
    }
    if (!hasAccess && category.subcategories.length === 0) {
        return <Intl id={`restriction.category_card.subtitle.${type}.restricted`}/>
    }
    return subcategoriesList(category.subcategories);
}

const subcategoriesList = (collection) => {
    if (collection.length < 3) {
        return collection.map((c) => c.name).join(' e ');
    }
    const count = collection.length - 1;
    const first = collection[0].name;
    return <Intl id={`restriction.category_card.subtitle.selected`} values={{count, first}}/>;
}