import { Avatar, Button, Card, Grid, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import AdvanceScheduleUserModal from "./AdvanceScheduleUserModal";

const AdvanceScheduleUserField = ({ validating, smallScreen, onChangeCompanyUserId, initialValue }) => {
  const [state, setState] = useState({
    opened: false,
    user: initialValue ?? null
  });

  const onOpen = () => {
    setState({ ...state, opened: true });
  }

  const onClose = () => {
    setState({ ...state, opened: false });
  }

  const onRemove = () => {
    setState({ ...state, user: null });
    onChangeCompanyUserId(0);
  }

  const onConfirm = (user) => {
    setState({ ...state, user: user, opened: false });
    onChangeCompanyUserId(user.company_user_id);
  }

  return <>
    <AdvanceScheduleUserModal
      open={state.opened}
      onClose={onClose}
      onConfirm={onConfirm}
    />
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">
          1. Associar usuário
        </Typography>
      </Grid>
      {
        validating && !state.user &&
        <Grid item xs={12} my={1}>
          <Typography color={"error"}>
            É obrigatório associar o usuário!
          </Typography>
        </Grid>
      }
      {
        !validating &&
        <Grid item xs={12} my={1}>
          <Typography color={"GrayText"}>
            { state.user ? "O usuário foi associado!"
              : "Selecione o usuário para configurar o registro do adiantamento." }
          </Typography>
        </Grid>
      }
      {
        !state.user &&
        <Grid item xs={ smallScreen ? 12 : 3 } mt={1}>
          <Button
            fullWidth={smallScreen}
            variant="contained"
            onClick={onOpen}
            data-testid={"user-button"}
          >
            ASSOCIAR USUÁRIO
          </Button>
        </Grid> 
      }
      {
        state.user &&
        <Grid item xs={12}>
          <Card sx={{ pr: 2 }}>
            <Stack direction="row" spacing={2}>
              <ListItem>
                <ListItemAvatar children={<Avatar src={state.user.photo}/>}/>
                <ListItemText primary={state.user.name} secondary={state.user.email}/>
              </ListItem>
              <Button
                color={"inherit"}
                onClick={onRemove}
              >
                REMOVER
              </Button>
            </Stack>
          </Card>
        </Grid>
      }
    </Grid>
  </>
}

export default AdvanceScheduleUserField;