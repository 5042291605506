import React, { useState } from "react";
import { Chip, Grid, InputAdornment, TextField } from "@mui/material";
import AdvanceScheduleCostAllocationModal from "./AdvanceScheduleCostAllocationModal";
import SearchIcon from '@mui/icons-material/Search';
import PieChartIcon from '@mui/icons-material/PieChart';
import CancelIcon from '@mui/icons-material/Cancel';

export const grayFocusedStyles = {
  "& .MuiInputLabel-root.Mui-focused:not(.Mui-error)": {
    color: "GrayText",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused:not(.Mui-error) fieldset": {
      border: "1px solid rgba(0, 0, 0, 0.3)",
    },
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    border: "1px solid red",
  },
}

const AdvanceScheduleCostAllocationField = ({
  validating, companyUserId, costAllocation, onSelectCostCenters, initialValue, onChangeAnyAccessibleCostCenter
}) => {
  const [state, setState] = useState({
    costCenters: initialValue ?? [],
    opened: false,
  });

  const onOpen = () => {
    setState({ ...state, opened: true });
  }

  const onClose = () => {
    setState({ ...state, opened: false });
  }

  const onConfirm = (costCenters) => {
    setState({ ...state, opened: false, costCenters: costCenters });
    onSelectCostCenters(costCenters.map(costCenter => costCenter.id));
  }

  const handleCostCenterDelete = (costCenter) => {
    const costCenters = state.costCenters.filter(selected => selected.id !== costCenter.id);
    setState({ ...state, costCenters: costCenters });
    onSelectCostCenters(costCenters.map(costCenter => costCenter.id));
  }

  const costCenterPercent = (index) => {
    const count = state.costCenters.length;
    if (count === 3) {
      return index === 0 ? 34 : 33;
    }
    return 100 / count;
  }

  const error = validating && costAllocation.required && state.costCenters.length === 0;

  return <>
    <AdvanceScheduleCostAllocationModal
      open={state.opened}
      selected={state.costCenters}
      title={costAllocation.name}
      companyUserId={companyUserId}
      onClose={onClose}
      onConfirm={onConfirm}
      onChangeAnyAccessibleCostCenter={onChangeAnyAccessibleCostCenter}
    />
    <Grid container columnSpacing={1}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          onClick={onOpen}
          focused={true}
          label={costAllocation.name}
          placeholder={"Selecione centro de custos"}
          error={error}
          helperText={ error && "Esse campo é obrigatório" }
          data-testid={"cost-allocation-field"}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon/>
              </InputAdornment>
            ),
          }}
          sx={grayFocusedStyles}
        />
      </Grid>
      {
        state.costCenters.length > 0 &&
        state.costCenters.map((costCenter, index) => (
          <Grid item key={index} mt={2}>
            <Chip
              variant="outlined"
              data-testid={"selected-cost-center"}
              label={`${costCenter.name} (${costCenterPercent(index)}%)`}
              icon={<PieChartIcon/>}
              onDelete={() => handleCostCenterDelete(costCenter)}
              deleteIcon={
                <CancelIcon
                  data-testid={"delete-cost-center"}
                />
              }
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.12)",
                "& .MuiChip-deleteIcon": {
                  color: "rgba(0, 0, 0, 0.32)",
                },
              }}
            />
          </Grid>
        ))
      }
    </Grid>
  </>
}

export default AdvanceScheduleCostAllocationField;