import React from "react";
import { parserDateTime } from "../../../shared/helpers";

export function parserPmaObject(pmaStatementItem) {
  return  [
    { label: "Empresa", description: pmaStatementItem.company_name },
    { label: "Descrição", description: pmaStatementItem.description },
    { label: "Valor", description: pmaStatementItem.cost },
    { label: "Data", description: pmaStatementItem.date_performed_at },
    { label: "Hora", description: pmaStatementItem.time_performed_at },
    { label: "Tipo", description: pmaStatementItem.operation_type },
    { label: "Status", description: pmaStatementItem.status_name },
    { label: "Retentativas", description: pmaStatementItem.retries },
    { label: "Ultima Retentativa", description: parseRetriedAt(pmaStatementItem.retried_at) }
  ];
}

function parseRetriedAt(retriedAt) {
  if(retriedAt != null) {
    return parserDateTime(retriedAt)
  }
}

export function renderStatementItemStatus(status) {
  if (status === "Concluído") {
    return statementItemStatusGreen(status)
  } else {
    return statementItemStatusRed(status)
  }
}

export function loadPmaSummary(successCallback) {
  $.ajax({
    type: "GET", dataType: "json", url: "/bistro/accounts/pma_statement/summary"
  })
  .then(successCallback)
  .catch((e) => {
    if (e.status === 401) {
      window.location.assign("/");
    }
    window.alert("ocorreu um erro ao buscar a o sumário da pma");
  });
}

const statementItemStatusGreen = (status) => {
  return (
    <span className='statement-status-color status-green'>
      { status }
    </span>
  );
}

const statementItemStatusRed = (status) => {
  return (
    <span className='statement-status-color status-red'>
      { status }
    </span>
  );
}
