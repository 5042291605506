import React, {useState} from "react";
import {ErpTemplateConfig} from "../ErpTemplateConfig";
import {OmieProjectConfigSteps} from "./OmieProjectConfigSteps";
import {OmieProjectConfigForm} from "./OmieProjectConfigForm";
import {OmieWebhookInfo} from "./OmieWebhookInfo";
import {updateTemplate} from "../../erp_template_connector";

export const OmieProjectConfig = ({integration, template}) => {
  const [restricted, setRestricted] = useState(template?.settings?.restricted || false)

  return <ErpTemplateConfig
    erp={'omie'}
    title={'Configuração de projeto'}
    validConfig={true}
    onConfigure={() => updateTemplate('omie', 'project', {restricted})}
    info={<OmieWebhookInfo/>}
    steps={<OmieProjectConfigSteps webhooksUrl={integration.webhooksUrl}/>}
    form={(loading, failed) => (
      <OmieProjectConfigForm
        restricted={restricted}
        failed={failed}
        disabled={loading}
        onChangeRestricted={setRestricted}/>
    )}/>
}

