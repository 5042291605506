import React, {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Button, DialogContent} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import {EventItem} from "./EventItem";
import {LoadingEventPlaceholder} from "./PaginetedList";
import {fetchRelatedEvents} from "../erp_template_event_connector";

export const RelatedEventsDialog = ({erp, templateType, event, onClose}) => {
    const [relatedEvents, setRelatedEvents] = useState([event])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchRelated = async () => {
            const data = await fetchRelatedEvents(erp, templateType, event.uuid)
            setRelatedEvents(data.length === 0 ? [event] : data)
            setLoading(false)
        }
        fetchRelated()
    }, [event]);

    return <Dialog
        fullWidth
        open={!!event}
        onClose={onClose}
        maxWidth={'lg'}>
        <DialogTitle>Eventos da integração</DialogTitle>
        <DialogContent>
            {[...relatedEvents.map((related, index) => {
                return loading ? (
                    <LoadingEventPlaceholder key={index}/>
                ) : (
                    <EventItem key={index} event={related} templateType={templateType}/>
                )
            })]}
        </DialogContent>
        <DialogActions>
            <Button color={'inherit'} onClick={onClose}>Fechar</Button>
        </DialogActions>
    </Dialog>
}