import React, {useMemo, useState} from "react";

import {Snackbar, Stack, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {
    admin_classifications_cost_allocations_cost_center_path,
    admin_classifications_custom_field_path,
    admin_classifications_tags_group_path
} from "../../../../routes";
import {PageNavbar} from "../../components/PageNavbar";
import EspressoTemplate from "../../EspressoTemplate";
import SelectModal from "../components/SelectModal";
import {fetchSubcategories, restrictClassification} from "../classifications_service";
import CategoryRestrictionCard from "./components/CategoryRestrictionCard";
import {FormattedMessage as Intl} from "react-intl";
import {hot} from "react-hot-loader";

function Index(props) {
    const {classification, categories, type} = props;

    const [category, setCategory] = useState(null);
    const [error, setError] = useState('');

    const selected = useMemo(() => {
        if (classification?.categoryRestricted && !category?.allSubcategories) {
            return category?.subcategories.map(subcategory => subcategory.id) ?? [];
        }
        return category?.inaccessible.map(subcategory => subcategory.id) ?? [];
    }, [classification, category]);

    const onOpen = (category) => {
        setCategory(category);
    }

    const onClose = () => {
        setCategory(null);
    }

    const onConfirm = async (category, allExcept, items) => {
        setCategory(null);
        const success = await restrictClassification(type, classification.id, category.id, items, allExcept);
        if (success) {
            window.location.reload();
        } else {
            setError('restriction.category_card.modal.restrict_error');
        }
    }

    const loadSubcategories = (page, search) => {
        return fetchSubcategories(category.id, page, search);
    }

    return <EspressoTemplate {...props}>
        <Stack>
            <PageNavbar
                backTo={backTo(type, classification.id)}
                title={`${classification.name} - Restrições`}/>
            <Container maxWidth={'md'} sx={{my: 5}}>
                <Stack gap={1}>
                    <Typography variant="h6">
                        Categorias
                    </Typography>
                    <Typography variant="body1" color={'textSecondary'}>
                        <Intl id={`restriction.${type}.subtitle`}/>
                    </Typography>
                    {categories.map(category => (
                        <CategoryRestrictionCard
                            type={type}
                            key={category.id}
                            restricted={classification.categoryRestricted}
                            category={category}
                            onSwitch={(event) => onConfirm(category, event.target.checked, [])}
                            onAction={() => onOpen(category)}/>
                    ))}
                    {category && (
                        <SelectModal
                            open={true}
                            onClose={onClose}
                            title={<Intl id={'restriction.category_card.modal.title'}/>}
                            selectAllTitle={<Intl id={'restriction.category_card.modal.select_all'}/>}
                            errorMessage={<Intl id={'restrictions.category_card.modal.search_error'}/>}
                            allExcept={!classification.categoryRestricted || category.allSubcategories}
                            selected={selected}
                            fetchItems={(page, search) => loadSubcategories(page, search)}
                            onConfirm={(allExcept, items) => onConfirm(category, allExcept, items)}/>
                    )}
                </Stack>
                {!!error && <Snackbar
                    open={!!error}
                    message={<Intl id={error}/>}
                    autoHideDuration={2000}
                    onClose={() => setError('')}/>}
            </Container>
        </Stack>
    </EspressoTemplate>
}

function backTo(type, classificationId) {
    switch (type) {
        case 'cost_center':
            return admin_classifications_cost_allocations_cost_center_path(classificationId)
        case 'tags_group':
            return admin_classifications_tags_group_path(classificationId)
        case 'custom_field':
            return admin_classifications_custom_field_path(classificationId)
    }
}

export default hot(module)(Index)