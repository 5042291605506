import React, { useState } from "react";
import { TextField } from "@mui/material";
import { grayFocusedStyles } from "./AdvanceScheduleCostAllocationField";

const AdvanceScheduleCustomField = ({ validating, customField, onChange, initialValue }) => {
  const [state, setState] = useState({
    value: initialValue ?? ''
  });

  const handleChange = (event) => {
    setState({ ...state, value: event.target.value });
    onChange(event.target.value);
  }

  const error = validating && customField.required && state.value === '';
  
  return <TextField
    fullWidth
    label={customField.name}
    placeholder={customField.description}
    value={state.value}
    onChange={handleChange}
    error={error}
    helperText={ error && "Esse campo é obrigatório" }
    data-testid={"custom-field-field"}
    focused={true}
    sx={grayFocusedStyles}
  />
}

export default AdvanceScheduleCustomField;