import React from 'react';
import {ShadowTheme} from "../../ShadowTemplate";
import SingleItemSetting from "./SingleItemSetting";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import {hot} from "react-hot-loader";
import {admin_classifications_classification_restrictions_path} from "../../../../routes";
import {FormattedMessage as Intl} from "react-intl";

function ClassificationRestrictionSectionShadow(props) {
    return <ShadowTheme rootSelector={props.rootSelector}>
        <ClassificationRestrictionSection {...props} />
    </ShadowTheme>
}

function ClassificationRestrictionSection({classificationId, restricted, type}) {
    const open = restricted ? 'restrictive' : 'open';

    return <SingleItemSetting
        icon={<AppRegistrationIcon/>}
        title={<Intl id={`restriction.classification_section.title`}/>}
        subtitle={<Intl id={`restriction.classification_section.${type}.subtitle`}/>}
        itemTitle={<Intl id={`restriction.classification_section.${type}.item_title.${open}`}/>}
        itemSubtitle={<Intl id={`restriction.classification_section.${type}.item_subtitle.${open}`}/>}
        actionLabel={<Intl id={`restriction.classification_section.action`}/>}
        actionHref={admin_classifications_classification_restrictions_path(type, classificationId)}
    />
}

export default hot(module)(ClassificationRestrictionSectionShadow);