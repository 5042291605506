import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Container, IconButton, LinearProgress, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import { hot } from "react-hot-loader";
import { fetchAdvanceScheduleLogsBy } from "./advance_schedules_service";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AdvanceScheduleDetailsModal from "./AdvanceScheduleDetailsModal";
import EspressoTemplate from "../EspressoTemplate";
import { PageNavbar } from "../components/PageNavbar";
import { admin_advance_schedules_path } from "../../../routes";

const AdvanceScheduleHistoric = (props) => {
  return <EspressoTemplate {...props}>
    <Stack>
      <PageNavbar
        showBackButton={true}
        title={"Histórico de ações"}
        backTo={admin_advance_schedules_path()}
      />
      <Container maxWidth={"lg"} sx={{ mt: 5 }}>
        <AdvanceScheduleHistoricContent id={props.id}/>
      </Container>
    </Stack>
  </EspressoTemplate>
}

const cells = ['Ação', 'Usuário', 'Data'];

const AdvanceScheduleHistoricContent = ({ id }) => {
  const [state, setState] = useState({
    data: [],
    pages: 0,
    details: [],
    loading: false,
    error: false,
  });

  const [page, setPage] = useState(1);

  const handlePage = (_, value) => {
    setPage(value);
  }

  const handleDetailsOpen = (details) => {
    setState({ ...state, details: details });
  }

  const handleDetailsClose = () => {
    setState({ ...state, details: [] });
  }

  const fetchHistoric = async () => {
    setState({ ...state, loading: true, error: false });
    const body = await fetchAdvanceScheduleLogsBy(id, page);
    setState({
      ...state,
      loading: false,
      data: body?.data ?? [],
      pages: body?.pages ?? 0,
      error: body === null
    });
  }

  useEffect(() => {
    fetchHistoric();
  }, [page]);

  return <>
    <AdvanceScheduleDetailsModal
      open={state.details.length > 0}
      details={state.details}
      onClose={handleDetailsClose}
    />
    <Card sx={{ p: 1, mb: 10 }}>
      <CardHeader
        title={"Histórico"}
        subheader={"Registro detalhado das configurações de recorrência de adiantamentos em espécie."}
      />
      <CardContent>
        {
          state.loading &&
          <LinearProgress sx={{ mb: 1 }}/>
        }
        {
          state.error &&
          <Typography color={"error"}>
            Erro ao buscar o histórico da recorrência
          </Typography>
        }
        {
          state.data.length > 0 &&
          <TableContainer
            component={Paper}
            elevation={0}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {
                    cells.map((cell, index) => (
                      <TableCell
                        key={index}
                        align={"left"}
                        sx={{ fontWeight: "bold" }}
                      >
                        {cell}
                      </TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  state.data.map((log, index) => (
                    <TableRow key={index}>
                      <TableCell align={"left"}>{log.action}</TableCell>
                      <TableCell align={"left"}>{log.user}</TableCell>
                      <TableCell align={"left"}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Typography>{log.date}</Typography>
                          {
                            log.details.length > 0 &&
                            <IconButton
                              size={"small"}
                              onClick={() => handleDetailsOpen(log.details)}
                              data-testid={"details-button"}
                            >
                              <MoreVertIcon/>
                            </IconButton>
                          }
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        }
        {
          state.pages > 0 &&
          <Stack
            alignItems={"end"}
            sx={{ mt: 2 }}
          >
            <Pagination
              shape="rounded"
              color="primary"
              count={state.pages}
              page={page}
              onChange={handlePage}
              data-testid={"pagination"}
            />
          </Stack>
        }
      </CardContent>
    </Card>
  </>
}

export default hot(module)(AdvanceScheduleHistoric);