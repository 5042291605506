import React, {useState} from "react";
import {ErpTemplateConfig} from "../ErpTemplateConfig";
import {OmieCostCenterConfigSteps} from "./OmieCostCenterConfigSteps";
import {OmieCostCenterConfigForm} from "./OmieCostCenterConfigForm";
import {OmieWebhookInfo} from "./OmieWebhookInfo";
import {updateTemplate} from "../../erp_template_connector";

export const OmieCostCenterConfig = ({integration, template}) => {
    const [restricted, setRestricted] = useState(template?.settings?.restricted || false)

    return <ErpTemplateConfig
        erp={'omie'}
        title={'Configuração de centros de custo'}
        validConfig={true}
        onConfigure={() => updateTemplate('omie', 'cost_center', {restricted})}
        info={<OmieWebhookInfo/>}
        steps={<OmieCostCenterConfigSteps webhooksUrl={integration.webhooksUrl}/>}
        form={(loading, failed) => (
            <OmieCostCenterConfigForm
                restricted={restricted}
                failed={failed}
                disabled={loading}
                onChangeRestricted={setRestricted}/>
        )}/>
}
