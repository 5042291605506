import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Fab, Stack, useMediaQuery } from "@mui/material";
import { ShadowTheme } from "../ShadowTemplate";
import AddIcon from '@mui/icons-material/Add';
import AdvanceScheduleEmptyPage from "./AdvanceScheduleEmptyPage";
import AdvanceScheduleFilter from "./AdvanceScheduleFilter";
import AdvanceScheduleFilterCard from "./AdvanceScheduleFilterCard";
import { new_admin_advance_schedule_path } from "../../../routes";
import AdvanceScheduleExpiredCard from "./AdvanceScheduleExpiredCard";
import AdvanceScheduleMainCard from "./AdvanceScheduleMainCard";
import { hot } from "react-hot-loader";

const IndexShadow = (props) => {
  return <ShadowTheme rootSelector={props.rootSelector}>
    <Index {...props}/>
  </ShadowTheme>
}

const Index = (props) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [filtering, setFiltering] = useState(false);
  const [refreshs, setRefreshs] = useState(0);

  const [filter, setFilter] = useState({
    search: '',
    status: '',
  });

  const onFilter = (search, status) => {
    setFilter({ search: search, status: status });
    setFiltering(true);
  }

  const onFilterClean = () => {
    setFilter({ search: '', status: '' });
    setFiltering(false);
  }

  const handleRefresh = () => {
    setRefreshs(refreshs + 1);
  }

  if (props.empty) {
    return <AdvanceScheduleEmptyPage
      theme={theme}
      smallScreen={smallScreen}
    />;
  }

  return <>
    <Stack
      sx={{ mx: smallScreen ? "1rem" : "10rem", p: 1, mb: 10, mt: 5 }}
    >
      <AdvanceScheduleFilter
        onFilter={onFilter}
        onClean={onFilterClean}
        smallScreen={smallScreen}
      />
      {
        filtering &&
        <AdvanceScheduleFilterCard
          search={filter.search}
          status={filter.status}
          smallScreen={smallScreen}
          refreshs={refreshs}
          onRefresh={handleRefresh}
        />
      }
      {
        !filtering &&
        <AdvanceScheduleExpiredCard
          smallScreen={smallScreen}
          refreshs={refreshs}
          onRefresh={handleRefresh}
        />
      }
      {
        !filtering &&
        <AdvanceScheduleMainCard
          smallScreen={smallScreen}
          refreshs={refreshs}
          onRefresh={handleRefresh}
        />
      }
    </Stack>
    <Fab
      color={"primary"}
      aria-label={"Novo fundo fixo"}
      data-testid={"new-advance-schedule-fab"}
      href={new_admin_advance_schedule_path()}
      sx={{ position: "fixed", right: 30, bottom: 30 }}
    >
      <AddIcon/>
    </Fab>
  </>
}

export default hot(module)(IndexShadow);